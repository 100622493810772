import React, { useState, useRef, forwardRef } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
} from 'reactstrap'
import NotificationAlert from "react-notification-alert";
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';


import { token, partnerId } from '../../utils'
import api from '../../services/api'

function VaccinesTable({ edit, remove }) {

    const notificationAlert = useRef(null);
    const [size, setPageSize] = useState(5);
    const tableVaccines = useRef();

    const tableIcons = {
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }


    function notify(id) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div >
                    <div>
                        <div className="text-center">
                            Deseja deletar a vacina selecionada?
                  </div>

                        <div className="d-flex justify-content-center">
                            <Button
                                className="btn-link"
                                color="light"
                                type="submit"
                                onClick={() => {
                                    deleteVaccine(id)
                                }
                                }
                            >
                                Sim
                    </Button>
                        </div>
                    </div>
                </div>
            ),

            type: "info",
            icon: "tim-icons",
            autoDismiss: false
        };
        notificationAlert.current.notificationAlert(options);
    };

    const getVaccines = async (query) => {
        return new Promise(resolve => {
            const config = {
                headers: { Authorization: `Bearer ${token()}` }
            }
            setPageSize(query.pageSize);
            if (query.search) {
                api.get(`/searchonlypartnervac?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
                    .then(res => {
                        
                        resolve({
                            data: res.data.data,
                            page: res.data.page - 1,
                            totalCount: res.data.total
                        })
                    })
                    .catch(err => {
                        console.log('Erro: ' + JSON.stringify(err.response.data))
                    })
            }
            else {
                api.get(`/getActivePartVac?partner_id=${partnerId()}&page=${query.page + 1}&limit=${query.pageSize}`, config)
                    .then(response => {
                        console.log(response.pageSize)
                        return resolve({
                            data: response.data.data,
                            page: response.data.page - 1,
                            totalCount: response.data.total
                        })
                    })
                    .catch(err => {
                        console.log('Erro: ' + JSON.stringify(err.response.data))
                    })
            }
        })

    }

    async function deleteVaccine(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        await api.delete('/partnervac/' + id, config)
            .then(response => {
                console.log("Vacina excluída com sucesso")
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function activateVaccine(id) {

        const data = { is_deleted: false }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/partnervac/' + id, data, config)
            .then(response => {
                console.log("Vacina ativado com sucesso!")
                window.location.reload();
            })
            .catch(error => {
                console.log("Erro! " + JSON.stringify(error.response.data))
            })

    }

    function editVaccine(id) {
        edit(id)
    }

    async function reduceVaccine(id, stock_) {
        // console.log(stock_ - 1)
        const data = { stock: stock_ - 1 }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/partnervac/' + id, data, config)
            .then(response => {
                console.log("Vacina removida com sucesso!")
                tableVaccines.current.onQueryChange()
            })
            .catch(error => {
                console.log("Erro! " + JSON.stringify(error.response.data))
            })

    }

    function ShowTrash(data) {
        if (data.is_deleted) {
            return (
                <Button
                    color="link"
                    type="button"
                    onClick={() => activateVaccine(data.id)}>
                    <i className="tim-icons icon-check-2" />
                </Button>);
        }
        else {
            return (
                <Button
                    color="link"
                    type="button"
                    onClick={() => notify(data.id)}>
                    <i className="tim-icons icon-trash-simple" />
                </Button>
            );
        }
    }


    return (
        <Card className="card-plain">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <CardHeader>
                <CardTitle tag="h4">Lista Das Suas Vacinas</CardTitle>
                <CardSubtitle className="category">Visualize, edite ou exclua suas vacinas</CardSubtitle>
            </CardHeader>
            <CardBody>
                <MaterialTable
                    tableRef={tableVaccines}
                    title="Lista de Todos os Medicamentos"
                    style={{ backgroundColor: "#f5f6fa" }}
                    columns={[
                        {
                            title: '', field: 'icon', render: (rowData) => (
                                <>
                                    {remove !== true ?
                                        <Button
                                            color="link"
                                            type="button"
                                            onClick={() => editVaccine(rowData.id)}
                                        >
                                            <i className="tim-icons icon-pencil" />
                                        </Button> : null}
                                    {remove !== true ? ShowTrash(rowData) : null}
                                    {
                                        rowData.stock > 0 && remove ?
                                            <Button
                                                color="link"
                                                type="button"
                                                onClick={() => reduceVaccine(rowData.id, rowData.stock)}
                                            >
                                                <i className="tim-icons icon-simple-delete" />
                                            </Button>
                                            :
                                            null
                                    }
                                </>
                            )
                        },
                        { title: 'Nome', field: 'vaccine_name', align: 'center' },
                        {
                            title: 'Dose', field: 'vaccine_dose', align: 'center',
                            render: rowData =>
                                <label style={{ fontSize: 14, color: 'black' }}>
                                    {rowData.vaccine_dose}ª dose
                                </label>
                        },
                        {
                            title: "Estoque", field: 'stock', align: 'center',
                            render: rowData =>
                                rowData.stock === 0 ?
                                    <label style={{ fontSize: 14, color: 'black' }}>Vazio</label>
                                    :
                                    <label style={{ fontSize: 14, color: 'black' }}>{rowData.stock}</label>

                        },
                        {
                            title: "Preço", field: 'price', width: 40, align: 'center',
                            render: rowData => <label style={{ fontSize: 14, color: 'black' }}>
                                R$ {parseFloat(rowData.price).toFixed(2)}
                            </label>
                        },
                        {
                            title: 'Ativo', field: 'is_deleted', align: 'center',
                            lookup: { 1: 'Não', 0: 'Sim' }
                        },
                        { title: '', field: '' }
                    ]}
                    options={{
                        showTitle: false,
                        header: true,
                        headerStyle: {
                            color: '#000',
                            fontWeight: 'bold',
                            backgroundColor: '#f5f6fa',
                            fontFamily: 'Poppins',
                        },
                        padding: 'default',
                        initialPage: 0,
                        pageSizeOptions: [1, 2, 5, 10, 15],
                        pageSize: size,
                        search: true,
                        searchFieldAlignment: 'left',
                        searchFieldStyle: {
                            color: '#000',
                            backgroundColor: (30, 30, 47, 0.3),
                        },
                    }}
                    localization={{

                        body: {
                            emptyDataSourceMessage: 'Sem vacinas para mostrar',
                        },
                        pagination: {
                            labelRowsSelect: 'linhas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            searchPlaceholder: 'Procurar'
                        },
                    }}
                    data={query => getVaccines(query)}
                    icons={tableIcons}
                />
            </CardBody>
        </Card>
    )

}

export default VaccinesTable;