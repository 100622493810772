import React, { useState } from "react";
import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

export default function FileInput(props) {

    const [fileName, setFileName] = useState("");
    const [invalidFile, setInvalidFile] = useState(false);

    function handleFileChange({ target: { files } }) {
        const cancel = !files.length;
        if (cancel) return;

        const [{ size, name }] = files;
        const maxSize = 2000000;
        let reader = new FileReader();

        if (size < maxSize) {

            reader.readAsDataURL(files[0])
            reader.onload = () => {
                props.path([reader.result])
            };
            props.file(files[0])
            setFileName(name)
            setInvalidFile(false)

        } else {

            setFileName("")
            setInvalidFile(true)

        }
    }

    return (
        <>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="tim-icons icon-cloud-upload-94" />
                    </InputGroupText>
                </InputGroupAddon>
                <div className="form-control">
                    <p>{fileName || ''}</p>
                    <Input
                        disabled={props.disabled}
                        type="file"
                        onChange={handleFileChange}
                        invalid={invalidFile} />
                </div>
            </InputGroup>
        </>
    )

}