import React, { useState, useEffect, useRef, forwardRef } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import NotificationAlert from "react-notification-alert";
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';


import { token } from '../../utils'
import api from '../../services/api'

export default function PartnerPendTable(props) {

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const notificationAlert = useRef(null);
  const [size, setPageSize] = useState(5);


  const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  }

  function notify(id) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div >
          <div>
            <div className="text-center">
              Deseja deletar o parceiro selecionado?
              </div>

            <div className="d-flex justify-content-center">
              <Button
                className="btn-link"
                color="light"
                type="submit"
                onClick={() => {
                  //console.log(false)
                  deletePartner(id)
                }
                }
              >
                Sim
                </Button>
            </div>
          </div>
        </div>
      ),

      type: "info",
      icon: "tim-icons",
      autoDismiss: false
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (props.allParts) {
      setTitle("Lista De Todos Os Estabelecimentos Que Você Não Trabalha")
      setSubtitle("Visualize ou solicite a entrada na equipe")
    }
    else if (props.pendingParts) {
      setTitle("Lista Dos Estabelecimentos Que Desejam Te Contratar")
      setSubtitle("Visualize, aceite ou recuse a entrada na equipe")
    }
    else {
      setTitle("Lista De Todos Os Estabelecimentos Que Você Trabalha")
      setSubtitle("Visualize, edite ou saia de um estabelecimento")
    }

  }, [props.allParts, props.pendingParts]);


  const getPendParts = async (query) => {
    return new Promise(resolve => {
      const config = {
        headers: { Authorization: `Bearer ${token()}` }
      }
      setPageSize(query.pageSize)
      if (query.search) {
        api.get(`/searchpendparts?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(res => {
            resolve({
              data: res.data.data,
              page: res.data.page - 1,
              totalCount: res.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
      else {
        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        api.get(`/getpendparts?page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(response => {
            resolve({
              data: response.data.data,
              page: response.data.page - 1,
              totalCount: response.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
    })
  }


  async function deletePartner(id) {

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    await api.delete('/clinicDoctor/' + id, config)
      .then(response => {
        console.log("Parceiro excluído com sucesso")
        window.location.reload();
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
      })

  }


  function editPartner(id, part_doc_id) {
    props.edit(id, part_doc_id)
  }

  function selectPartner(id) {
    props.select(id)
  }

  return (

    <Card className="card-plain" >
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        {
          // console.log("parceiro")
        }
        <CardSubtitle className="category">{subtitle}</CardSubtitle>
      </CardHeader>
      <CardBody>
        <MaterialTable
          title="Lista de Todos os Medicamentos"
          style={{ backgroundColor: "#f5f6fa" }}
          columns={[
            {
              title: '', field: 'icon', render: (rowData) => (
                <>
                  {
                    props.pendingParts
                      ?
                      <Button
                        color="link"
                        type="button"
                        onClick={() => selectPartner(rowData.id)}
                      >
                        <i className={"tim-icons icon-single-02"} />
                      </Button>
                      :
                      <>
                        <Button
                          color="link"
                          type="button"
                          onClick={() => editPartner(rowData.id, rowData.part_doc_id)}
                        >
                          <i className="tim-icons icon-pencil" />
                        </Button>
                        <Button
                          color="link"
                          type="button"
                          onClick={() => notify(rowData.part_doc_id)}
                        >
                          <i className="tim-icons icon-trash-simple" />
                        </Button>
                      </>
                  }

                </>
              )
            },
            { title: 'Nome', field: 'name', width: 20, align: 'center' },
            { title: 'Email', field: 'email', with: 10, align: 'center' },
            {
              title: 'Categoria', field: 'category', width: 10, align: 'center',
              lookup: { 0: 'Produto Comercial', 1: 'Amostra Grátis' },
            },
            { title: "Whatsapp", field: 'whatsapp_phone', width: 10, align: 'center' },
            { title: 'Telefone', field: 'phone', with: 15, align: 'center' },
            { title: 'Endereço', field: 'address', with: 15, align: 'center' }
          ]}
          options={{
            showTitle: false,
            header: true,
            headerStyle: {
              color: '#000',
              fontWeight: 'bold',
              backgroundColor: '#f5f6fa',
              fontFamily: 'Poppins',
            },
            padding: 'default',
            initialPage: 0,
            pageSizeOptions: [1, 2, 5, 10, 15],
            pageSize: size,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              color: '#000',
              backgroundColor: (30, 30, 47, 0.3),
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Sem clínicas para mostrar',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Procurar'
            },
          }}
          data={query =>

            getPendParts(query)

          }
          icons={tableIcons}
        />
      </CardBody>
    </Card>
  )
}