import React, { Component } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";


import NotificationAlert from "react-notification-alert";

import CircularProgress from '@material-ui/core/CircularProgress';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Col,
    Row,
} from "reactstrap";


import api from "../services/api";

var ps;

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "blue",
            loading: false,
            email: "",
            // notifications: [],
        };
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
    }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            if (navigator.platform.indexOf("Win") > -1) {
                let tables = document.querySelectorAll(".table-responsive");
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    handleBgClick = (color) => {
        this.setState({ backgroundColor: color });
    };

    // handleNotificationPush = (el) => {
    //     const arr = [...this.state.notifications];
    //     arr.push(el);
    //     this.setState({ notifications: arr });
    // }

    sendMail = async (successFunc, errorFunc) => {

        await api.post('/forgot', { email: this.state.email })
            .then(function (response) {
                successFunc(response);
            })
            .catch(function (err) {
                errorFunc(err);
                console.log('Erro: ' + JSON.stringify(err.response.data));
            });

    };

    notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    submit = async () => {
        if (this.state.email.length === 0) {
            this.notify("warning", "É necessário preencher com um email cadastrado!");
        }
        else {
            this.setState({ loading: true });
            await this.sendMail(
                (resp) => {
                    this.notify("success", "Email enviado com sucesso!");
                },
                (err) => {
                    this.notify("warning", "Erro! Não é possível enviar o email de redefinição de senha!");
                }
            );
            this.setState({ loading: false });
        }

    };

    render() {
        return (
            <>
                <div className="wrapper">
                    <div
                        className="main-panel"
                        ref="mainPanel"
                        data={this.state.backgroundColor}
                    >
                        <div className="react-notification-alert-container">
                            <NotificationAlert ref="notificationAlert" />
                        </div>
                        <div className="login">
                            <Card className="card-user login-card">
                                <CardBody>
                                    <CardText />
                                    <div className="author">
                                        <img
                                            alt="..."
                                            className="med-mais-logo"
                                            src={require("../assets/img/icon_med_blue.png")}
                                        />
                                    </div>
                                    <div className="card-description">
                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            <h2>Esqueci minha senha</h2>
                                        </div>
                                        <Form>
                                            <FormGroup>
                                                <label htmlFor="exampleInputEmail1">Insira seu E-mail cadastrado</label>
                                                <Input placeholder="exemplo@email.com" type="email" onChange={(txt) => this.setState({ email: txt.target.value })} />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    {
                                        this.state.loading ?
                                            <Row className="justify-content-md-center">
                                                <Col md="auto">
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                            :
                                            <div className="button-container">
                                                <Button className="btn-fill" color="info" type="submit" onClick={this.submit}>
                                                    Enviar
                    </Button>
                                            </div>
                                    }
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                </div>
                <FixedPlugin
                    bgColor={this.state.backgroundColor}
                    handleBgClick={this.handleBgClick}
                />
            </>
        );
    }
}
document.body.classList.add("white-content");
