import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import {
  Row,
  Col,
} from "reactstrap";


import CardTable from './Components/Card-Table';

// Icones
import icon2 from './Dashboard_Icons/Patologias.svg'
import icon5 from './Dashboard_Icons/MedicamentosMaisUtilizados.svg'
import icon6 from './Dashboard_Icons/ClinicasMaisProcuradas.svg'
import icon7 from './Dashboard_Icons/AmostrasRequisitadas.svg'

const c1 = [
  { title: 'NOME', field: 'disease', align: 'left' }
]


const c2 = [
  { title: 'NOME', field: 'name', align: 'left' },
  { title: 'COMPOSTOS', field: 'compostos', align: 'center' },
  { title: 'Nº DE CLÍNICAS', field: 'qtd_clinicas', align: 'center' },
  { title: 'Nº DE PACIENTES', field: 'qtd_pessoas_pesquisaram', align: 'center' }
]



const c3 = [
  { title: 'NOME', field: 'name', align: 'left' },
  { title: 'TELEFONE', field: 'phone', align: 'left' },
  { title: 'E-MAIL', field: 'email', align: 'left' }
]


const c4 = [
  { title: 'NOME', field: 'name', align: 'left' },
  { title: 'COMPOSTO', field: 'compostos', align: 'center' },
  { title: 'Nº DE CLÍNICAS', field: 'qtd_clinicas', align: 'center' },
  { title: 'Nº DE PACIENTES', field: 'qtd_pessoas_pesquisaram', align: 'center' }
]
  





export default function DashboardRanking(props) {
 
  const [medicines, setMedicines] = useState([]);
  const [patologias, setPatologias] = useState([]);
  const [amostras, setAmostras] = useState([]);
  const [clinicas, setClinicas] = useState([]);

  useEffect(() => {
    medicamento();
    getMaisPatologias();
    amostra();
    clinica();
  }, [])
  

  async function medicamento() {
    await api.get('/getmed')
      .then(response => {
        setMedicines(response.data[0])
      
      })
      .catch(error => {
        console.log("error");
      })
  }

  async function getMaisPatologias(){

    await api.get('/getdisease')
      .then(response => {
        setPatologias(response.data[0])
      })
      .catch(error => {
        console.log(error)
      })
  }

  
  async function amostra(){
    await api.get('/getamostra')
      .then(response =>{
        setAmostras(response.data[0]);
      })
      .catch(error =>{
        console.log('error')
      })
  }
  async function clinica(){
    await api.get('/getsearchedpartners')
      .then(response =>{
        setClinicas(response.data[0])
      })
      .catch(error =>{
        console.log('error')
      })
  }

  return (
    <>
      <Row>
        <Col sm={4}>
          <CardTable
            icon={icon2}
            title="Patologias Mais Comuns"
            colunas={c1}
            dados={patologias} />
        </Col>

        <Col sm={8}>
          <CardTable
            icon={icon5}
            title="Medicações Mais Utilizadas"
            colunas={c2}
            dados={medicines} />
        </Col>
      </Row>

      <Row>
        <Col>
          <CardTable
            icon={icon6}
            title="Clínicas Mais Procuradas"
            colunas={c3}
            dados={clinicas} />
        </Col>
        <Col>
          <CardTable
            icon={icon7}
            title="Amostras Requisitadas"
            colunas={c4}
            dados={amostras} />
        </Col>
      </Row>

    </>
  )
}