/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import MedDataTable from "./components/MedDataTable";
import MedicinesTable from "./components/MedicinesTable"
import MedCreateTable from "./components/MedCreateTable";

function MedicinesPartner() {

    const [medId, setMedId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const userPartner = true; //definirá se os campos estarão habilitados ou não

    const handleMedicineEdit = (id) => {
        setMedId(id);
        setIsEditable(true);
    }

    const handleMedicineSelect = (id) => {
        setMedId(id);
        setIsEditable(false);
    }

    return (
        <>
            <div className="content">
                <MedDataTable
                    isPartner={userPartner}
                    select={handleMedicineSelect}
                />
                <MedCreateTable
                    disabled={userPartner}
                    isPartner={userPartner}
                    medicineId={medId}
                    isEditable={isEditable}
                />
                <MedicinesTable edit={handleMedicineEdit} />
            </div>
        </>
    );

}

export default MedicinesPartner;
