import React, { Component } from "react";
import api from '../services/api';

// core components
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";

import NotificationAlert from "../components/NotificationAlert";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
} from "reactstrap";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      password: "",
      rPassword: "",
      notifications: [],
    };
  }

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleNotificationPush = (el) => {
    const arr = [...this.state.notifications];
    arr.push(el);
    this.setState({ notifications: arr });
  };

  validatePassword = () => {

    this.setState({ notification: { visible: false } });

    // Valida o tamanho da nova senha
    if (this.state.password.length < 6 || this.state.password.length > 25) {
      const notification = {
        message: "Senhas devem possuir mais de 6 caracteres e menos que 25",
        visible: true,
        situation: "warning"
      };
      this.handleNotificationPush(notification);
      return false;
    }

    // Valida se as senhas são iguais
    if (this.state.password !== this.state.rPassword) {
      const notification = {
        message: "Senhas devem coincidir",
        visible: true,
        situation: "warning"
      };
      this.handleNotificationPush(notification);
      return false;
    }

    return true;
  };

  getTokenFromUrl = () => {
    let url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    return token;
  };

  callResetPassword = async (callbackSuccess, callbackFailed) => {
    if (this.validatePassword()) {
      let token = this.getTokenFromUrl();
      await api.post('/reset', {
        token: token,
        password: this.state.password,
        password_confirmation: this.state.rPassword
      }).then(function (response) {
        callbackSuccess(response);
      }).catch(function (err) {
        callbackFailed(err);
      });
    }
  };

  submit = async () => {
    await this.callResetPassword(
      (response) => {
        const notification = {
          message: "Senha atualizada com sucesso",
          visible: true,
          situation: "success"
        };
        this.handleNotificationPush(notification);
      },
      (err) => {
        const notification = {
          message: "Token de acesso expirado",
          visible: true,
          situation: "warning"
        };
        this.handleNotificationPush(notification);
      }
    );
  };

  render() {
    return (
      <>
        {
          this.state.notifications.map((n, i) => {
            return (<NotificationAlert
              key={i}
              message={n.message}
              visible={n.visible}
              color={n.situation}
            />);
          })
        }
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <div className="login">
              <Card className="card-user login-card">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <img
                      alt="..."
                      className="med-mais-logo"
                      src={require("../assets/img/icon_med_blue.png")}
                    />
                  </div>
                  <div className="card-description">
                    <Form>
                      <FormGroup>
                        <label >Nova senha</label>
                        <Input placeholder="Digite sua senha" type="password" onChange={(txt) => this.setState({ password: txt.target.value })} />
                      </FormGroup>
                      <FormGroup>
                        <label>Repetir nova senha</label>
                        <Input placeholder="Repita sua senha" type="password" onChange={(txt) => this.setState({ rPassword: txt.target.value })} />
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-fill" color="info" type="submit" onClick={() => this.submit()}>
                      Trocar senha
                        </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}
