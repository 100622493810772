/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import PartnerForm from "./Partner/PartnerForm.jsx";
import PartnerTable from "./Partner/PartnerTable.jsx"
import PartnerPic from "./Partner/PartnerPic.jsx";
import AdmForm from "./Administrator/AdmForm.jsx";
import AdmPic from "./Administrator/AdmPic.jsx";
import AdmTable from "./Administrator/AdmTable.jsx";
// nodejs library that concatenates classes
import classNames from "classnames";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Row,
  Col,
} from "reactstrap";

function Register() {

  const [userType, setUserType] = useState("Partner");
  const [partnerId, setPartnerId] = useState(null);
  const [admId, setAdmId] = useState(null);
  const [admName, setAdmName] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [admPath, setAdmPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
  const [partnerPath,setPartnerPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
  const[userId, setUserId] = useState(null);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <h5 className="title">Cadastre ou edite um usuário</h5>
                  </Col>
                  <Col>
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: userType === "Partner"
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setUserType("Partner")}
                      >
                        <input
                          defaultChecked
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Parceiro
                          </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-bag-16" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: userType === "Admin"
                        })}
                        onClick={() => setUserType("Admin")}
                      >
                        <input
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Administrador
                          </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              {userType === "Partner" ? <PartnerForm partnerId={partnerId}  userId = {userId} name = {setPartnerName} path = {setPartnerPath}/> : <AdmForm admId={admId} name={setAdmName} path = {setAdmPath}/>}
            </Card>
          </Col>
          <Col md="4">
            {userType === "Partner" ? <PartnerPic name = {partnerName} path = {partnerPath}/> : <AdmPic name={admName} path = {admPath}/>}
          </Col>
        </Row>
        {userType === "Partner" ? <PartnerTable edit={(id,user_id) => {setPartnerId(id);setUserId(user_id)}}/> : <AdmTable edit={(id) => setAdmId(id)} />}
      </div>
    </>
  );
}

export default Register;
