import React, { useState, useEffect, forwardRef } from 'react'
import api from '../../../services/api'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    FormGroup,
    Form,
    Label,
    Input,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";

import { mask } from 'remask';

import {
    Title,
    SubTitle
} from '../styles'

import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';


import icone from '../Dashboard_Icons/InformacaoDosUsuarios.svg'
import filtro from '../Dashboard_Icons/Filtro.svg'

function Filter(props) {

    return (
        <>
            <Card style={{ backgroundColor: '#E5E5E5' }}>
                <CardHeader>
                    <Row>
                        <Col className="float-left">
                            <Title>{props.title}</Title>
                        </Col>
                    </Row></CardHeader>
                <CardBody>
                    <Col className="float-left">
                        {props.children}
                    </Col>
                </CardBody>
            </Card>
        </>
    )
}

const estados_brasileiros = [
    ['acre', 'amazonas', 'pará', 'rondônia', 'roraima', 'tocantis', 'amapá'],
    ['maranhão', 'piauí', 'ceará', 'rio Grande do Norte', 'pernambuco', 'paraíba', 'sergipe', 'alagoas', 'bahia'],
    ['mato Grosso', 'mato Grosso do Sul', 'goiás'],
    ['são Paulo', 'rio de Janeiro', 'espírito Santo', 'minas Gerais'],
    ['paraná', 'rio Grande do Sul', 'santa Catarina']
]

const all_estados = ["acre", "alagoas", "amapá", "amazonas", "bahia", "ceará", "espírito Santo", "goiás", "maranhão", "mato Grosso", "mato Grosso do Sul", "minas Gerais", "paraná", "paraíba", "pará", "pernambuco", "piauí", "rio Grande do Norte", "rio Grande do Sul", "rio de Janeiro", "rondônia", "roraima", "santa Catarina", "sergipe", "são Paulo", "tocantis"]

function dropDownEstados(regiao, func) {

    if (regiao === "norte")
        return (
            estados_brasileiros[0].sort().map((e, index) =>
                <DropdownItem key={index} onClick={() => { func(e) }}>
                    {e[0].toUpperCase() + e.slice(1)}
                </DropdownItem>
            ));
    else if (regiao === "nordeste")
        return (
            estados_brasileiros[1].sort().map((e, index) =>
                <DropdownItem key={index} onClick={() => { func(e) }}>
                    {e[0].toUpperCase() + e.slice(1)}
                </DropdownItem>
            ));
    else if (regiao === "centro_oeste")
        return (
            estados_brasileiros[2].sort().map((e, index) =>
                <DropdownItem key={index} onClick={() => { func(e) }}>
                    {e[0].toUpperCase() + e.slice(1)}
                </DropdownItem>)
        );
    else if (regiao === "sudeste")
        return (
            estados_brasileiros[3].sort().map((e, index) =>
                <DropdownItem key={index} onClick={() => { func(e) }}>
                    {e[0].toUpperCase() + e.slice(1)}
                </DropdownItem>)
        );
    else if (regiao === "sul")
        return (
            estados_brasileiros[4].sort().map((e, index) =>
                <DropdownItem key={index} onClick={() => { func(e) }}>
                    {e[0].toUpperCase() + e.slice(1)}
                </DropdownItem>)
        );
}
//Função especifíca para tratamento de string
function cleaner(str, select) {
    if (str === '' || select === false) {
        return 'null';
    }

    let nome = str.replaceAll(' ', '_')

    nome = nome.replace(/[ÀÁÂÃÄÅ]/g, "A");
    nome = nome.replace(/[àáâãäå]/g, "a");

    nome = nome.replace(/[ÈÉÊË]/g, "E");
    nome = nome.replace(/[èéêë]/g, "e");

    nome = nome.replace(/[ÍÌÎÏ]/g, "I");
    nome = nome.replace(/[ìíïî]/g, "i");

    nome = nome.replace(/[ÓÒÖÕÔ]/g, "O");
    nome = nome.replace(/[óòöõô]/g, "o");

    nome = nome.replace(/[ÚÙÛÜ]/g, "U");
    nome = nome.replace(/[úùûü]/g, "u");

    return nome;
}
function inverteData(data, select) {
    if (data === '' || select === false) {
        return 'null';
    }
    return data[6] + data[7] + data[8] + data[9] + data[5] + data[3] + data[4] + data[2] + data[0] + data[1];
}
export default function CardUserFilter(props) {
    // Controladores das Checkbox
    const [regiaoSelector, setRegiaoSelector] = useState(false)
    const [estadoSelector, setEstadoSelector] = useState(false)
    const [cidadeSelector, setCidadeSelector] = useState(false)
    const [idadeSelector, setIdadeSelector] = useState(false)
    const [sexoSelector, setSexoSelector] = useState(false)
    const [visitasSelector, setVisitasSelector] = useState(false)
    const [buscasSelector, setBuscasSelector] = useState(false)


    // Visibilidade dos filtros
    const [regiaoAtivador, setRegiaoAtivador] = useState(false)
    const [estadoAtivador, setEstadoAtivador] = useState(false)
    const [cidadeAtivador, setCidadeAtivador] = useState(false)
    const [idadeAtivador, setIdadeAtivador] = useState(false)
    const [sexoAtivador, setSexoAtivador] = useState(false)
    const [visitasAtivador, setVisitasAtivador] = useState(false)
    const [buscasAtivador, setBuscasAtivador] = useState(false)
    const [table, setTableVisible] = useState(false)


    //Inputs dos Filtros
    const [regiao, setRegiao] = useState("")
    const [estado, setEstado] = useState("")
    const [cidade, setCidade] = useState("")
    const [idadeMin, setIdadeMin] = useState("")
    const [idadeMax, setIdadeMax] = useState("")
    const [sexo, setSexo] = useState("")
    const [visitasMin, setVisitasMin] = useState("")
    const [visitasMax, setVisitasMax] = useState("")
    const [buscas, setBuscas] = useState("")
    const [allMed, setAllMed] = useState([])
    const [resulatdo, setResultado] = useState([])

    
    
    const tableIcons = {
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }

    const desativador = () => {
        if (regiaoAtivador === true) setRegiaoAtivador(false);
        else if (estadoAtivador === true) setEstadoAtivador(false);
        else if (cidadeAtivador === true) setCidadeAtivador(false);
        else if (idadeAtivador === true) setIdadeAtivador(false);
        else if (sexoAtivador === true) setSexoAtivador(false);
        else if (visitasAtivador === true) setVisitasAtivador(false);
        else if (buscasAtivador === true) setBuscasAtivador(false);
    }
    async function getmed() {
        await api.get('/allmedication')
            .then(response => {
                setAllMed(response.data[0]);

                // console.log(allMed)
            })
            .catch(error => {
                console.log('error')
            })
    }

    async function allfilters() {
        await api.get(`/getallfilters/${cleaner(sexo, sexoSelector)}/${cleaner(cidade, cidadeSelector)}/${cleaner(estado, estadoSelector)}/${cleaner(regiao, regiaoSelector)}/${inverteData(idadeMin, idadeSelector)}/${inverteData(idadeMax, idadeSelector)}/${cleaner(buscas, buscasSelector)}/${cleaner(visitasMin, visitasSelector)}/${cleaner(visitasMax, visitasSelector)}`)
            .then(response => {
                // console.log(response.data[0])
                setResultado(response.data[0]);
                // console.log(resulatdo);
            })
            .catch(erro => {
                console.log('error')
            })
    }

    useEffect(() => {
        getmed();

    }, [])

    return (
        <Card className="card-chart"
            style={{
                borderRadius: '20px',
                boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
                minHeight: '70vh'
            }}>
            <CardHeader>
                <Row>
                    <Col sm={10}>
                        <Title>Informações dos Usuários</Title>
                        <Row>
                            <Col style={{ marginLeft: '20px' }}>
                                <SubTitle> Filtrar por: <img src={filtro} alt="filtrar" style={{ fill: '#3358F4' }} />  </SubTitle>
                                <Form>
                                    <FormGroup check >
                                        <Col style={{ marginLeft: '10px' }}>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="regiao"
                                                        checked={regiaoSelector}
                                                        onChange={() => {
                                                            setRegiaoSelector(!regiaoSelector);
                                                            desativador();
                                                            if (regiaoSelector === false) setRegiaoAtivador(true);

                                                        }}
                                                    />
                                                Por região
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="estado"
                                                        checked={estadoSelector}
                                                        onChange={() => {
                                                            setEstadoSelector(!estadoSelector);
                                                            desativador();
                                                            if (estadoSelector === false) setEstadoAtivador(true);
                                                        }}
                                                    />
                                                Por estado
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="cidade"
                                                        checked={cidadeSelector}
                                                        onChange={() => {
                                                            setCidadeSelector(!cidadeSelector);
                                                            desativador();
                                                            if (cidadeSelector === false) setCidadeAtivador(true);
                                                        }}
                                                    />
                                                Por cidade
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="idade"
                                                        checked={idadeSelector}
                                                        onChange={() => {
                                                            setIdadeSelector(!idadeSelector);
                                                            desativador();
                                                            if (idadeSelector === false) setIdadeAtivador(true);
                                                        }}
                                                    />
                                                Por idade ou faixa de idade
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="sexo"
                                                        checked={sexoSelector}
                                                        onChange={() => {
                                                            // console.log(sexoSelector)
                                                            setSexoSelector(!sexoSelector);
                                                            desativador();
                                                            if (sexoSelector === false) setSexoAtivador(true);
                                                        }}
                                                    />
                                                Por sexo
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>
                                                    <Input
                                                        type="checkbox"
                                                        value="visitas"
                                                        checked={visitasSelector}
                                                        onChange={() => {
                                                            setVisitasSelector(!visitasSelector);
                                                            desativador();
                                                            if (visitasSelector === false) setVisitasAtivador(true);
                                                        }}
                                                    />
                                                Por quantidade de visitas na plataforma
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                            <Row style={{ marginBottom: '7px' }}>
                                                <Label check style={{
                                                    fontSize: '16px',
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: '500',
                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                }}>

                                                    <Input
                                                        type="checkbox"
                                                        value="buscas"
                                                        checked={buscasSelector}
                                                        onChange={() => {
                                                            setBuscasSelector(!buscasSelector);
                                                            desativador();
                                                            if (buscasSelector === false) setBuscasAtivador(true);
                                                        }}
                                                    />
                                                Buscando medicações
                                                <span className="form-check-sign"></span>
                                                </Label>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Button color="info" onClick={() => {
                                    allfilters();
                                    desativador();
                                    setTableVisible(true);
                                }}>FILTRAR</Button>
                            </Col>
                            <Col>
                                {
                                    regiaoAtivador ?
                                        <Filter title="Escolha uma região:">
                                            <Form>
                                                <FormGroup>
                                                    <Col style={{ marginLeft: '25px' }}>
                                                        <Row>
                                                            <Label check style={{
                                                                fontSize: '16px',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: '500',
                                                                color: 'rgba(0, 0, 0, 0.7)'
                                                            }}>
                                                                <Input
                                                                    type="radio"
                                                                    value="norte"
                                                                    checked={regiao === "norte"}
                                                                    onClick={() => { setRegiao("norte") 
                                                                    // console.log(regiao)
                                                                 }}
                                                                /> Norte
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label check style={{
                                                                fontSize: '16px',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: '500',
                                                                color: 'rgba(0, 0, 0, 0.7)'
                                                            }}>
                                                                <Input
                                                                    type="radio"
                                                                    value="nordeste"
                                                                    checked={regiao === "nordeste"}
                                                                    onClick={() => { setRegiao("nordeste") }}
                                                                /> Nordeste
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label check style={{
                                                                fontSize: '16px',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: '500',
                                                                color: 'rgba(0, 0, 0, 0.7)'
                                                            }}>
                                                                <Input
                                                                    type="radio"
                                                                    value="sul"
                                                                    checked={regiao === "sul"}
                                                                    onClick={() => { setRegiao("sul") }}
                                                                /> Sul
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label check style={{
                                                                fontSize: '16px',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: '500',
                                                                color: 'rgba(0, 0, 0, 0.7)'
                                                            }}>
                                                                <Input
                                                                    type="radio"
                                                                    value="sudeste"
                                                                    checked={regiao === "sudeste"}
                                                                    onClick={() => { setRegiao("sudeste") }}
                                                                /> Sudeste
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label check style={{
                                                                fontSize: '16px',
                                                                fontFamily: "'Poppins', sans-serif",
                                                                fontWeight: '500',
                                                                color: 'rgba(0, 0, 0, 0.7)'
                                                            }}>
                                                                <Input
                                                                    type="radio"
                                                                    value="centro_oeste"
                                                                    checked={regiao === "centro_oeste"}
                                                                    onClick={() => { setRegiao("centro_oeste") }}
                                                                /> Centro-Oeste
                                                            </Label>
                                                        </Row>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Filter>
                                        : null
                                }
                                {
                                    estadoAtivador ?
                                        <Filter title="Escolha um estado:">
                                            <UncontrolledDropdown color="info" style={{ position: 'relative', top: '0', right: '0' }}>
                                                <DropdownToggle caret className="dropdown-toggle btn btn-secondary" color="info">
                                                    Selecionar Estado
                                                </DropdownToggle>
                                                <DropdownMenu modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                                ...data,
                                                                styles: {
                                                                    ...data.styles,
                                                                    overflow: 'auto',
                                                                    maxHeight: '300px',
                                                                },
                                                            };
                                                        },
                                                    },
                                                }}>
                                                    <DropdownItem header>Selecionar Estado</DropdownItem>
                                                    {regiaoSelector && regiao !== "" ? dropDownEstados(regiao, setEstado) : all_estados.map((e, index) =>
                                                        <DropdownItem key={index} onClick={() => { setEstado(e) }}>
                                                            {e[0].toUpperCase() + e.slice(1)}
                                                        </DropdownItem>
                                                    )
                                                    }

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <span>Selecionado: {estado !== "" ? estado[0].toUpperCase() + estado.slice(1) : 'nenhum'} </span>
                                        </Filter>
                                        : null
                                }
                                {cidadeAtivador ?
                                    <Filter title="Escreva uma Cidade:">
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <FormGroup>
                                                <Input
                                                    style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                    type="text"
                                                    placeholder="Ex: Salvador"
                                                    value={cidade}
                                                    onChange={(e) => { setCidade(e.target.value) }}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </Filter> : null}
                                {idadeAtivador ?
                                    <Filter title="Escolha um intervalo:">
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <FormGroup>
                                                <Row>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around'
                                                    }}>
                                                        <div>
                                                            <label>Idade Mínima</label>
                                                            <Input
                                                                style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                                type="datetime"
                                                                placeholder="DD-MM-AAAA"
                                                                value={idadeMin}
                                                                onChange={(e) => { setIdadeMin(mask(e.target.value, '99-99-9999')) }}
                                                            />
                                                        </div>
                                                        <span style={{ margin: '0 10px' }}>até</span>
                                                        <div>
                                                            <label>Idade Máxima</label>
                                                            <Input
                                                                style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                                type="datetime"
                                                                placeholder="DD-MM-AAAA"
                                                                value={idadeMax}
                                                                onChange={(e) => { setIdadeMax(mask(e.target.value, '99-99-9999')) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </FormGroup>
                                        </Form>
                                    </Filter> : null}
                                {sexoAtivador ?
                                    <Filter title="Escolha o sexo:">
                                        <Form>
                                            <FormGroup>
                                                <Col style={{ marginLeft: '25px' }}>
                                                    <Row>
                                                        <Label check style={{
                                                            fontSize: '16px',
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: '500',
                                                            color: 'rgba(0, 0, 0, 0.7)'
                                                        }}>
                                                            <Input
                                                                type="radio"
                                                                value="masculino"
                                                                checked={sexo === "M"}
                                                                onChange={() => { setSexo("M") }}
                                                            /> Masculino
                                                            </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label check style={{
                                                            fontSize: '16px',
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: '500',
                                                            color: 'rgba(0, 0, 0, 0.7)'
                                                        }}>
                                                            <Input
                                                                type="radio"
                                                                value="feminino"
                                                                checked={sexo === "F"}
                                                                onChange={() => { setSexo("F") }}
                                                            /> Feminino
                                                            </Label>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Filter> : null}
                                {visitasAtivador ?
                                    <Filter title="Escolha um intervalo:">
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <FormGroup>
                                                <Row>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around'
                                                    }}>
                                                        <div>
                                                            <label>Visitas Mínimas</label>
                                                            <Input
                                                                style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                                type="number"
                                                                placeholder="Valor mínimo"
                                                                value={visitasMin}
                                                                onChange={(e) => { setVisitasMin(e.target.value) }}
                                                            />
                                                        </div>
                                                        <span style={{ margin: '0 10px' }}>até</span>
                                                        <div>
                                                            <label>Visitas Máximas</label>
                                                            <Input
                                                                style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                                type="number"
                                                                placeholder="Valor máximo"
                                                                value={visitasMax}
                                                                onChange={(e) => { setVisitasMax(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </FormGroup>
                                        </Form>
                                    </Filter> : null}
                                {buscasAtivador ?
                                    <Filter title="Escolha um medicamento:">
                                        <Form>
                                            <FormGroup>
                                                <Col>
                                                    <UncontrolledDropdown style={{ position: 'relative', top: '0', right: '0' }}>
                                                        <DropdownToggle caret color="info" className="dropdown-toggle btn btn-secondary" >
                                                            Selecionar Medicamento
                                                </DropdownToggle>
                                                        <DropdownMenu modifiers={{
                                                            setMaxHeight: {
                                                                enabled: true,
                                                                order: 890,
                                                                fn: (data) => {
                                                                    return {
                                                                        ...data,
                                                                        styles: {
                                                                            ...data.styles,
                                                                            overflow: 'auto',
                                                                            maxHeight: '300px',
                                                                        },
                                                                    };
                                                                },
                                                            },
                                                        }}>
                                                            <DropdownItem header>Selecionar Medicação</DropdownItem>
                                                            {allMed.map((e, index) =>
                                                                <DropdownItem key={index} onClick={() => { setBuscas(e.nome) }}>
                                                                    {e.nome}
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <span>Selecionado: {buscas !== "" ? buscas : 'nenhum'} </span>
                                    </Filter> : null}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={2}>
                        <div>
                            <img src={icone} alt="icone" style={{ maxHeight: '40px' }} />
                        </div>
                    </Col>
                </Row>
                {table ?
                    <Row>
                        <Col>
                            <MaterialTable
                                columns={[
                                    { title: 'NOME', field: 'name', align: 'center' },
                                    { title: 'SEXO', field: 'gender', align: 'center' },
                                    { title: 'E-MAIL', field: 'email', align: 'center' },
                                    { title: 'TELEFONE', field: 'phone', align: 'center' },
                                    { title: 'ENDEREÇO', field: 'address', align: 'center' }
                                ]}
                                data={resulatdo}

                                options={
                                    {
                                        showTitle: false,
                                        header: true,
                                        search: false,
                                        emptyRowsWhenPaging: false,
                                        filtering: false,
                                        grouping: false,
                                        toolbar: false,
                                        paging: true,
                                        pageSizeOptions: [1, 2, 5, 10, 15],

                                        headerStyle: {
                                            color: 'rgba(0,0,0,0.5)',
                                            fontWeight: '300',
                                            fontFamily: "'Poppins' sans-serif",
                                            fontSize: '15px'
                                        }

                                    }

                                }

                                style={{
                                    boxShadow: '0 0 0 black',
                                    fontSize: '15px',
                                    fontWeight: '550',
                                    color: 'rgba(0,0,0,0.5)'
                                }}

                                icons={tableIcons}
                                
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'Sem usuários para mostrar',
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        firstTooltip: 'Primeira página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    : null}
            </CardHeader>
        </Card >
    )
}