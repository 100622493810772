/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { mask } from 'remask'
import React, { useState, useEffect, useRef } from "react";
import FileInput from "../components/FileInput.jsx"
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import WorkPlacesTable from './Doctors/WorkPlacesTable'
import cep from 'cep-promise';
import { token, doctorId, userId } from '../utils'
import api from '../services/api'

function Doctors() {
    const [workPlaces, setWorkPlaces] = useState([]);
    const [gender, setGender] = useState(null);
    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState("");
    const [specialities, setSpecialities] = useState("");
    const [errorSpec, setErrorSpec] = useState("");
    const [cpf, setCpf] = useState("");
    const [errorCpf, setErrorCpf] = useState("");
    const [crm, setCrm] = useState("");
    const [errorCrm, setErrorCrm] = useState("");
    const [phone, setPhone] = useState("");
    const [cepAddress, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState("")
    const [path, setPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
    const [file, setFile] = useState(null);
    const notificationAlert = useRef(null);
    const [loading, setLoading] = useState(true)

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function error() {
        if (name.length !== 0 & cpf.length !== 0 & crm.length !== 0 & email.length !== 0 &
            specialities.length !== 0)
            return false;
        else {
            if (name.length === 0)
                setErrorName("has-danger")
            if (email.length === 0)
                setErrorEmail("has-danger")
            if (cpf.length === 0)
                setErrorCpf("has-danger")
            if (crm.length === 0)
                setErrorCrm("has-danger")
            if (specialities.length === 0)
                setErrorSpec("has-danger");

            notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
            return true;
        }
    }

    const handleNameChange = (e) => {
        e.preventDefault();
        let value = e.target.value
        setName(value);
        if (value.length === 0) {
            // input vazio
            setErrorName("has-danger")
        }
        else
            setErrorName("")
    }

    const handleCepChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let val = value
        setCep(val);
        if (val.length === 9) {
            cep(value).then(function (endereco) {
                setCity(endereco.city);
                setAddress(endereco.street + ", " + endereco.neighborhood);
                setState(endereco.state);
                setCountry("Brasil")
            });
        }
    };


    useEffect(() => {
        getDoctor(doctorId())
    }, []);

    async function getDoctor(id, edit) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/doctor/' + id, config)
            .then(response => {
                setName(response.data.name || "")
                setSpecialities(response.data.specialities.join(", ") || "")
                setCpf(response.data.cpf || "")
                setCrm(response.data.crm || "")
                setPhone(response.data.phone || "");
                setState(response.data.state || "");
                setAddress(response.data.address || "");
                setCep(response.data.cep || "");
                setCity(response.data.city || "");
                setCountry(response.data.country || "");
                setEmail(response.data.email || "");
                setGender(response.data.gender || null)
                setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
                response.data.clinics.forEach(clinic => {
                    clinic.address === null ?
                        setWorkPlaces(prevState => prevState.concat({
                            partner_id: clinic.id,
                            name_address: clinic.name,
                            days: clinic.days,
                            is_accepted: clinic.is_accepted
                        }))
                        :
                        setWorkPlaces(prevState => prevState.concat({
                            partner_id: clinic.id,
                            name_address: clinic.name + ' - ' + clinic.address,
                            days: clinic.days,
                            is_accepted: clinic.is_accepted
                        }))
                });
                setLoading(false)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar informações sobre as clínicas disponíveis! Tente novamente mais tarde.");
            })

    }

    async function editDoctor(id) {

        const data = {
            name,
            crm,
            specialities: specialities.split(","),
            phone,
            cep: cepAddress,
            address,
            country,
            state,
            city,
            gender
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/doctor/' + id, data, config)
            .then(response => {
                console.log("Médico atualizado com sucesso")

                storePicture(userId())
                notify("success", "Dados do médico atualizado com sucesso!");
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro! Não foi possível atualizar os dados do médico!")
            })

    }

    async function storePicture(id) {

        let data = new FormData();
        if (file)
            data.append('profile_pic', file, file.fileName);
        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/sessions/' + id + "/image", data, config)
            .then(response => {
                console.log("Foto atualizada com sucesso")
                notify("success", "Foto atualizada com sucesso!")
            })
            .catch(error => {
                console.log('Erro: ' + JSON.stringify(error.response.data))
                notify("warning", "Erro! Não foi possível atualizar foto!")
            })

    }

    function submit() {
        if (!error())
            setLoading(true)
            editDoctor(doctorId())
            setLoading(false)
    }

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Edite seu perfil</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup className={errorName}>
                                                <label>Nome*</label>
                                                <Input
                                                    placeholder="Nome completo"
                                                    type="text"
                                                    value={name}
                                                    onChange={handleNameChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup className={errorCpf}>
                                                <label>CPF*</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="Cadastro de Pessoa Física"
                                                    type="text"
                                                    value={cpf}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup className={errorCrm}>
                                                <label>CRM*</label>
                                                <Input
                                                    onChange={(txt) => {
                                                        setCrm(txt.target.value)
                                                        if (crm.length !== 0)
                                                            setErrorCrm("")
                                                    }}
                                                    placeholder="Conselho Regional de Medicina"
                                                    type="text"
                                                    value={crm}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="8">
                                            <FormGroup className={errorEmail}>
                                                <label>E-mail*</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="exemplo@hotmail.com"
                                                    type="text"
                                                    value={email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Foto</label>
                                                <FileInput path={setPath} file={setFile} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        loading ?
                                            <Row className="justify-content-md-center">
                                                <Col md="auto">
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col>
                                                    <FormGroup className={errorSpec}>
                                                        <label>Especialidades*</label>
                                                        <Input
                                                            onChange={(txt) => {
                                                                setSpecialities(txt.target.value)
                                                                if (specialities.length !== 0)
                                                                    setErrorSpec("")
                                                            }}
                                                            placeholder="Especialidades"
                                                            type="text"
                                                            value={specialities}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                    }
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label>Telefone</label>
                                                <Input
                                                    placeholder="Número de telefone"
                                                    type="text"
                                                    value={phone}
                                                    onChange={(txt) => {
                                                        setPhone(mask(txt.target.value, '+99(99)99999-9999'))
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="6">
                                            <FormGroup>
                                                <label>CEP</label>
                                                <Input

                                                    placeholder="CEP"
                                                    type="text"
                                                    value={cepAddress}
                                                    onChange={(txt) => setCep(mask(txt.target.value, '99999-999'))}
                                                    onBlur={handleCepChange}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Logradouro</label>
                                                <Input
                                                    onChange={(txt) => setAddress(txt.target.value)}
                                                    placeholder="Avenida, Bairo, Rua, Número...0"
                                                    type="text"
                                                    value={address}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>País</label>
                                                <Input
                                                    placeholder="País"
                                                    type="text"
                                                    value={country}
                                                    onChange={(txt) => setCountry(txt.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup>
                                                <label>Estado</label>
                                                <Input
                                                    placeholder="Estado"
                                                    type="text"
                                                    value={state}
                                                    onChange={(txt) => setState(txt.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Cidade</label>
                                                <Input
                                                    placeholder="Cidade"
                                                    type="text"
                                                    value={city}
                                                    onChange={(txt) => setCity(txt.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label>Sexo</label>
                                            <div className="ml-3">
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"

                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios11"
                                                                value="M"
                                                                checked={gender === 'M'}
                                                                onChange={() => setGender('M')}
                                                            />
                                                            Masculino
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"

                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios12"
                                                                value="F"
                                                                checked={gender === 'F'}
                                                                onChange={() => setGender('F')}
                                                            />
                                                            Feminino
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="card-plain">
                                                <CardBody>
                                                    <WorkPlacesTable workPlaces={workPlaces} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    className="btn-fill"
                                    color="info"
                                    type="submit"
                                    onClick={submit}
                                >
                                    Salvar
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <CardText />
                                <div className="author">
                                    <a href="#pablo" onClick={e => e.preventDefault()} className="d-flex justify-content-center">
                                        <div className="img-partner">
                                            <img
                                                alt="..."

                                                src={path}
                                            />
                                        </div>
                                    </a>


                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className="button-container">
                                    <h5 className="description">{name}</h5>
                                    <p className="description">{specialities}</p>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Doctors;
