import React, { useState, useRef, forwardRef } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
} from 'reactstrap'
import NotificationAlert from "react-notification-alert";
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';

import { token } from '../../utils'
import api from '../../services/api'

function VacDataTable(props) {

    const notificationAlert = useRef(null);
    const [size, setPageSize] = useState(5);

    const tableIcons = {
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }

    function notify(id) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div >
                    <div>
                        <div className="text-center">
                            Deseja deletar a vacina selecionada?
                  </div>
                        <div className="d-flex justify-content-center">
                            <Button
                                className="btn-link"
                                color="light"
                                type="submit"
                                onClick={() => {
                                    deleteVaccine(id)
                                }
                                }
                            >
                                Sim
                    </Button>
                        </div>
                    </div>
                </div>
            ),

            type: "info",
            icon: "tim-icons",
            autoDismiss: false
        };
        notificationAlert.current.notificationAlert(options);
    };

    const getVaccines = async (query) => {
        return new Promise(resolve => {
            const config = {
                headers: { Authorization: `Bearer ${token()}` }
            }
            setPageSize(query.pageSize)
            if (query.search) {
                api.get(`/searchvac?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
                    .then(res => {
                        resolve({
                            data: res.data.data,
                            page: res.data.page - 1,
                            totalCount: res.data.total
                        })
                    })
                    .catch(err => {
                        console.log('Erro: ' + JSON.stringify(err.response.data))
                    })
            }
            else {
                //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
                api.get(`/vaccine?page=${query.page + 1}&limit=${query.pageSize}`, config)
                    .then(response => {
                        resolve({
                            data: response.data.data,
                            page: response.data.page - 1,
                            totalCount: response.data.total
                        })
                    })
                    .catch(err => {
                        console.log('Erro: ' + JSON.stringify(err.response.data))
                    })
            }
        })
    }

    const getActiveVaccines = async (query) => {
        return new Promise(resolve => {
            const config = {
                headers: { Authorization: `Bearer ${token()}` }
            }
            setPageSize(query.pageSize)
            if (query.search) {
                api.get(`/searchActiveVac?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
                .then(res => {
                    resolve({
                        data: res.data.data,
                        page: res.data.page - 1,
                        totalCount: res.data.total
                    })
                })
                .catch(err => {
                    console.log('Erro: ' + JSON.stringify(err.response.data))
                })
            }
            else {
                //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
                api.get(`/getActiveVac?page=${query.page + 1}&limit=${query.pageSize}`, config)
                    .then(response => {
                        resolve({
                            data: response.data.data,
                            page: response.data.page - 1,
                            totalCount: response.data.total
                        })
                    })
                    .catch(err => {
                        console.log('Erro: ' + JSON.stringify(err.response.data))
                    })
            }    
        })        
    }


    async function deleteVaccine(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        await api.delete('/vaccine/' + id, config)
            .then(response => {
                console.log("Vacina excluída com sucesso")
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function activateVaccine(id) {

        const data = { is_deleted: false }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/vaccine/' + id, data, config)
            .then(response => {
                console.log("Vacina ativada com sucesso!")
                window.location.reload();
            })
            .catch(error => {
                console.log("Erro! " + JSON.stringify(error.response.data))
            })

    }

    function selectVaccine(id) {
        props.select(id)
    }

    function editVaccine(id) {
        props.edit(id)
    }

    return (
        <Card className="card-plain">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <CardHeader>
                <CardTitle tag="h4">Lista De Todas As Vacinas</CardTitle>
                <CardSubtitle className="category">Busque e selecione uma vacina que deseja adicionar ao seu estoque</CardSubtitle>
            </CardHeader>
            <CardBody>
                <MaterialTable
                    title="Lista de Todos os Medicamentos"
                    style={{ backgroundColor: "#f5f6fa" }}
                    columns={[
                        {
                            title: '', field: 'icon', render: (rowData) => (
                                props.isPartner === true ?
                                    <>
                                        <Button
                                            color="link"
                                            type="button"
                                            onClick={() => selectVaccine(rowData.id)}
                                        >
                                            <i className="tim-icons icon-simple-add" />
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            color="link"
                                            type="button"
                                            onClick={() => editVaccine(rowData.id)}
                                        >
                                            <i className="tim-icons icon-pencil" />
                                        </Button>
                                        {
                                            rowData.is_deleted
                                                ?
                                                <Button
                                                    color="link"
                                                    type="button"
                                                    onClick={() => activateVaccine(rowData.id)}
                                                >
                                                    <i className="tim-icons icon-check-2" />
                                                </Button>
                                                :
                                                <Button
                                                    color="link"
                                                    type="button"
                                                    onClick={() => notify(rowData.id)}
                                                >
                                                    <i className="tim-icons icon-trash-simple" />
                                                </Button>
                                        }
                                    </>
                            )
                        },
                        { title: 'Nome', field: 'name', width: 20, align: 'center' },
                        {
                            title: 'Dose', field: 'dose', with: 10, align: 'center',
                            render: rowData =>
                                <label style={{ fontSize: 14, color: 'black' }}>
                                    {rowData.dose}ª dose
                                </label>
                        },
                        {
                            title: 'Mês recomendado', field: 'start_recomend', width: 10, align: 'center',
                            render: rowData =>
                                rowData.start_recomend === 1
                                    ?
                                    <label style={{ fontSize: 14, color: 'black' }}>
                                        {rowData.start_recomend} mês
                        </label>
                                    :
                                    <label style={{ fontSize: 14, color: 'black' }}>
                                        {rowData.start_recomend} meses
                                </label>
                        },
                        { title: "Sexo recomendado", field: 'recomended_gender', width: 10, align: 'center' },
                        {
                            title: 'Intervalo próxima dose', field: 'gap_next_dose', with: 15, align: 'center',
                            render: rowData =>
                                rowData.gap_next_dose === null ?
                                    null
                                    :
                                    rowData.gap_next_dose === 1 ?
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.gap_next_dose} mês
                            </label>
                                        :
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.gap_next_dose} meses
                            </label>
                        },
                        {
                            title: 'Margem inferior', field: 'limit_bottom', with: 15, align: 'center',
                            render: rowData =>
                                rowData.limit_bottom === null ?
                                    null
                                    :
                                    rowData.limit_bottom === 1 ?
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.limit_bottom} mês
                                </label>
                                        :
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.limit_bottom} meses
                            </label>
                        },
                        {
                            title: 'Margem superior', field: 'limit_top', with: 15, align: 'center',
                            render: rowData =>
                                rowData.limit_top === null ?
                                    null
                                    :
                                    rowData.limit_top === 1 ?
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.limit_top} mês
                                    </label>
                                        :
                                        <label style={{ fontSize: 14, color: 'black' }}>
                                            {rowData.limit_top} meses
                                    </label>
                        },
                        props.isPartner
                            ?
                            {}
                            :
                            {
                                title: 'Ativo', field: 'is_deleted', align: 'center',
                                lookup: { 1: 'Não', 0: 'Sim' },
                            },
                    ]}
                    options={{
                        showTitle: false,
                        header: true,
                        headerStyle: {
                            color: '#000',
                            fontWeight: 'bold',
                            backgroundColor: '#f5f6fa',
                            fontFamily: 'Poppins',
                        },
                        padding: 'default',
                        initialPage: 0,
                        pageSizeOptions: [1, 2, 5, 10, 15],
                        pageSize: size,
                        search: true,
                        searchFieldAlignment: 'left',
                        searchFieldStyle: {
                            color: '#000',
                            backgroundColor: (30, 30, 47, 0.3),
                        },
                    }}
                    localization={{

                        body: {
                            emptyDataSourceMessage: 'Sem vacinas para mostrar',
                        },
                        pagination: {
                            labelRowsSelect: 'linhas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        },
                    }}
                    data={query =>
                        props.isPartner ?
                            getActiveVaccines(query)
                            :                            
                            getVaccines(query)
                    }
                    icons={tableIcons}
                />
            </CardBody>
        </Card>
    )

}

export default VacDataTable;