/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import FileInput from "../../../components/FileInput";
// import Notifications from "views/Notifications.jsx";

import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
    Button,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import cep from 'cep-promise'

import { token } from '../../../utils'
import api from '../../../services/api'
import { mask, unMask } from 'remask'
export default function PartnerForm(props) {
    async function storePicture(id) {

        let data = new FormData();
        data.append('profile_pic', file, file.fileName);
        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/sessions/' + id + "/image", data, config)
            .then(response => {
                console.log("Foto atualizada com sucesso")
                window.location.reload();
            })
            .catch(error => {
                console.log('Erro: ' + JSON.stringify(error.response.data))
                notify("warning", "Erro ao atualizar a foto! Tente mais tarde.")
                window.location.reload();
            })

    }
    //Dados do formulário
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPass, setConfPass] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [cepCampo, setCepCampo] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [category, setCategory] = useState(0);
    const [whatsapp, setWhatsapp] = useState("");
    const [phone, setPhone] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(false);
    // const [path, setPath] = useState("https://i.ytimg.com/vi/L1tx-wAI6Nw/maxresdefault.jpg");
    const [file, setFile] = useState({ fileName: "" });
    const [loading, setLoading] = useState(false);

    //Outras variáveis
    const notificationAlert = useRef(null);
    const [edit, setEdit] = useState(false);
    const [errorPassword, setErrorPassword] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorConf, setErrorConf] = useState("");
    const [errorCep, setErrorCep] = useState("");
    const [errorLat, setErrorLat] = useState("");
    const [errorLng, setErrorLng] = useState("");
    const [errorCnpj, setErrorCnpj] = useState('');

    const handleCepChange = (e) => {
        e.preventDefault();
        let value = e.target.value;

        let val = value
        if (val.length === 10) {
            let valu = value.split("-");
            val = valu[0].split(".")
            val = val[0] + val[1] + valu[1]
            // console.log(val)
        }
        setCepCampo(val);
        if (val.length === 9) {
            cep(value).then(function (endereco) {
                setCity(endereco.city);
                setAddress(endereco.street + ", " + endereco.neighborhood);
                setState(endereco.state);
                setCountry("Brasil")
                setErrorCep("")
            });
        }
        else if (value.length > 10) {
            setErrorCep("has-danger");
        }

    };
    useEffect(() => {
        props.name(name)
    }, [name]);

    const handleNameChange = (e) => {
        e.preventDefault();
        let value = e.target.value
        setName(value);
        if (value.length === 0) {
            // input vazio
            setErrorName("has-danger")
        }
        else
            setErrorName("")
    }

    const handleEmailChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setEmail(value);
        if (value.length === 0) {
            // input vazio
            setErrorEmail("has-danger")
        }
        else
            setErrorEmail("")
    }

    const handleLatChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setLat(value);
        if (value.length === 0) {
            // input vazio
            setErrorLat("has-danger")
        }
        else
            setErrorLat("")
    }

    const handleLongChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setLng(value);
        if (value.length === 0) {
            // input vazio
            setErrorLng("has-danger")
        }
        else
            setErrorLng("")
    }

    const handleCategoryChange = (op) => {
        op.preventDefault();
        let value = op.target.value;
        setCategory(value);
    }

    const handlePasswordChange = (e) => {
        let value = e.target.value;
        setPassword(value);
    }

    const handleCnpjChange = (txt) => {

        setCnpj(mask(txt.target.value, '99.999/9999-99'))
        let value = txt.target.value;
        if (value.length !== 0) {
            setErrorCnpj("");
        }
    }

    function onBlurPasswordHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorPassword("has-danger")
        }
    }

    const handleConfPassChange = (e) => {
        let value = e.target.value;
        setConfPass(value);
    }

    function onBlurConfHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorConf("has-danger")
        }
    }


    useEffect(() => {
        if (props.partnerId){
            setLoading(true)
            getPartner(props.partnerId)
        }            
    }, [props.partnerId]);

    async function getPartner(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/partner/' + id, config)
            .then(response => {
                setEdit(true)
                // console.log(edit)
                setPassword("")
                setConfPass("")
                setName(response.data.name || "")
                setEmail(response.data.email || "")
                setCnpj(response.data.cnpj || "")
                setCepCampo(response.data.cep || "")
                setAddress(response.data.address || "")
                setCountry(response.data.country || "")
                setState(response.data.state || "")
                setCity(response.data.city || "")
                setLat(response.data.lat || "")
                setLng(response.data.lng || "")
                setCategory(response.data.category || 0)
                setWhatsapp(response.data.whatsapp_phone || "")
                setPhone(response.data.phone || "")
                setDescription(response.data.description || "")
                setPriority(response.data.is_priority || false)
                props.path(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
                
            })
            .catch(err => {
                notify("warning", "Erro ao buscar dados da clínica parceira selecionada! Tente mais tarde.")
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })
            setLoading(false)
    }

    async function storePartner() {

        const data = {
            name,
            email,
            password,
            type_connection: "local",
            phone,
            whatsapp_phone: whatsapp,
            cnpj,
            address,
            state,
            city,
            country,
            cep: cepCampo,
            lat,
            lng,
            description,
            category,
            is_priority: priority
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/partner', data, config)
            .then(response => {
                console.log("Cadastrado com sucesso")
                notify("success", "Usuário cadastrado com sucesso!")
                if (file.fileName !== "")
                    storePicture(response.data.id)
                else
                    window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err))
                if (err.status === 400) {
                    notify("warning", "Erro! Nem todos os dados preenchidos são válidos!")
                }
                else
                    notify("warning", "Erro! Não foi possível cadastrar o usuário! Tente mais tarde.")
            })

    }

    async function editPartner(id, user_id) {

        const data = {
            name,
            phone,
            whatsapp_phone: whatsapp,
            cnpj,
            address,
            state,
            city,
            country,
            cep: cepCampo,
            lat,
            lng,
            description,
            category,
            is_priority: priority
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/partner/' + id, data, config)
            .then(response => {
                console.log("Usuário editado com sucesso")
                notify("success", "Usuário atualizado com sucesso!")
                if (file.fileName !== "")
                    storePicture(user_id)
                else
                    window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                if (err.status === 400) {
                    notify("warning", "Erro! Nem todos os dados preenchidos são válidos!")
                }
                else
                    notify("warning", "Erro! Não foi possível atualizar os dados! Tente mais tarde.");
            })
    }


    function submit() {

        if (!error()) {
            setLoading(true)
            if (edit) {
                editPartner(props.partnerId, props.userId)
            }
            else {
                storePartner()
            }
            setLoading(false)
        }
    }

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);

    };

    function error() {
        if (name.length !== 0 && email.length !== 0 && lat.length !== 0 && lng.length !== 0 && cnpj.length !== 0) {
            if (!edit && password.length !== 0
                && confPass.length !== 0) {
                if (lat.length > 24 || lng.length > 24) {
                    if (lat.length > 24) {
                        setErrorLat("has-danger")
                        notify("warning", "Erro! O campo de latitude foi preenchido de forma inválida!")
                    }
                    if (lng.length > 14) {
                        setErrorLng("has-danger");
                        notify("warning", "Erro! O campo de longitude foi preenchido de forma inválida!")
                    }
                }
                else if (validPassword())
                    return false;
                return true;
            }
            return false;
        }
        else {
            if (name.length === 0) {
                setErrorName("has-danger")
            }
            if (email.length === 0) {
                setErrorEmail("has-danger");
            }
            if (lat.length === 0) {
                setErrorLat("has-danger");
            }
            if (lng.length === 0) {
                setErrorLng("has-danger");
            }
            if (cnpj.length === 0) {
                setErrorCnpj("has-danger")
            }
            if (!edit) {
                if (password.length === 0) {
                    setErrorPassword("has-danger")
                }
                else if (confPass.length === 0)
                    setErrorConf("has-danger")
            }
            notify("warning", "Nem todos os campos foram preenchidos!" +
                " Por favor preencha todos os campos obrigatórios para poder atualizar seu cadastro!")
        }
        return true;
    }


    function validPassword() {
        if (password !== confPass) {
            setErrorPassword("has-danger");
            setErrorConf("has-danger");
            notify("warning", "A senha informada não é igual a senha confirmada!")
            return false;
        }
        else {
            setErrorPassword("");
            setErrorConf("");
            return true;
        }
    }

    return (
        <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <CardBody>
                <Form>
                    <Row>
                        <Col className="pr-md-1" md="5">
                            <FormGroup className={errorName} >
                                <label>Nome do parceiro*</label>
                                <Input
                                    value={name}
                                    placeholder="Nome da clínica ou farmácia"
                                    type="text"
                                    onChange={handleNameChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                            <FormGroup className={errorEmail}>
                                <label htmlFor="exampleInputEmail1">
                                    E-mail*
                          </label>
                                <Input
                                    disabled={edit}
                                    value={email}
                                    placeholder="exemplo@email.com"
                                    type="email"
                                    onChange={handleEmailChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="3">
                            <FormGroup>
                                <label>Foto</label>
                                <FileInput path={(path) => props.path(path)} file={setFile} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="6">
                            <FormGroup className={errorPassword}>
                                <Label for="error">Senha*</Label>
                                <Input
                                    disabled={edit}
                                    value={password}
                                    placeholder="Digite a senha do parceiro"
                                    type="password"
                                    onChange={handlePasswordChange}
                                    onBlur={onBlurPasswordHandle}
                                />

                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="6">
                            <FormGroup className={errorConf}>
                                <label >Confirme a senha*</label>
                                <Input
                                    disabled={edit}
                                    value={confPass}
                                    placeholder="Digite a mesma senha"
                                    type="password"
                                    onChange={handleConfPassChange}
                                    onBlur={onBlurConfHandle}
                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="6">
                            <FormGroup className={errorCnpj}>
                                <label>CNPJ*</label>
                                <Input
                                    value={cnpj}
                                    placeholder="CNPJ do parceiro"
                                    type="text"
                                    onChange={handleCnpjChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="6">
                            <FormGroup className={errorCep}>
                                <label>CEP</label>
                                <Input
                                    value={cepCampo}
                                    placeholder="CEP do parceiro"
                                    type="text"
                                    onChange={(txt) => setCepCampo(mask(txt.target.value, '99999-999'))}
                                    onBlur={handleCepChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        loading ?
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <CircularProgress />
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label>Logradouro</label>
                                        <Input
                                            value={address}
                                            placeholder="Endereço do parceiro"
                                            type="text"
                                            onChange={(txt) => setAddress(txt.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col className="pr-md-1" md="4">
                            <FormGroup>
                                <label>País</label>
                                <Input
                                    value={country}
                                    placeholder="País do parceiro"
                                    type="text"
                                    onChange={(txt) => setCountry(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                            <FormGroup>
                                <label>Estado</label>
                                <Input
                                    value={state}
                                    placeholder="Estado do parceiro"
                                    type="text"
                                    onChange={(txt) => setState(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                            <FormGroup>
                                <label>Cidade</label>
                                <Input
                                    value={city}
                                    placeholder="Cidade do parceiro"
                                    type="text"
                                    onChange={(txt) => setCity(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="4">
                            <FormGroup className={errorLat}>
                                <label>Latitude*</label>
                                <Input
                                    value={lat}
                                    placeholder="-99.999999"
                                    type="text"
                                    onChange={handleLatChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                            <FormGroup className={errorLng}>
                                <label>Longitude*</label>
                                <Input
                                    value={lng}
                                    placeholder="99.999999"
                                    type="text"
                                    onChange={handleLongChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                            <FormGroup >
                                <label>Tipo de produto*</label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect1"
                                    value={category}
                                    onChange={handleCategoryChange}
                                >
                                    <option value={0}>Produto comercial</option>
                                    <option value={1}>Amostra grátis</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="4">
                            <FormGroup>
                                <label>WhatsApp</label>
                                <Input
                                    value={whatsapp}
                                    placeholder="WhatsApp do parceiro"
                                    type="text"
                                    onChange={(txt) => setWhatsapp(mask(txt.target.value, '+99(99)99999-9999'))}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                            <FormGroup>
                                <label>Telefone</label>
                                <Input
                                    value={phone}
                                    placeholder="Telefone do parceiro"
                                    type="text"
                                    onChange={(txt) => setPhone(mask(unMask(txt.target.value), ['+99(99)9999-9999', '+99(99)99999-9999']))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8">
                            <FormGroup>
                                <label>Descrição</label>
                                <Input
                                    value={description}
                                    cols="80"
                                    placeholder="Descreva aqui os serviços prestados pelo parceiro e outras informações importantes..."
                                    rows="4"
                                    type="textarea"
                                    onChange={(txt) => setDescription(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <label>Tem prioridade</label>
                            <div className="ml-3">
                                <Row>
                                    <FormGroup
                                        check
                                        inline
                                        className="form-check-radio"

                                    >
                                        <Label className="form-check-label">
                                            <Input
                                                type="radio"
                                                name="exampleRadios1"
                                                id="exampleRadios11"
                                                value="Sim"
                                                checked={priority}
                                                onChange={() => setPriority(true)}
                                            />
                                                            Sim
                                                            <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup
                                        check
                                        inline
                                        className="form-check-radio"

                                    >
                                        <Label className="form-check-label">
                                            <Input
                                                type="radio"
                                                name="exampleRadios1"
                                                id="exampleRadios12"
                                                value="Não"
                                                checked={!priority}
                                                onChange={() => setPriority(false)}
                                            />
                                                            Não
                                                            <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={submit}>
                    {edit ? "Salvar" : "Cadastrar"}
                </Button>
            </CardFooter>
        </>
    );
}