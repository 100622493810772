/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardText
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import FormData from 'form-data';
import { mask } from 'remask';
import FileInput from "../components/FileInput.jsx";
import cep from 'cep-promise';
import api from "../services/api";

export default function DoctorRegister(props) {

    const notificationAlert = useRef(null);

    const [days, setDays] = useState([]);
    const [partners, setPartners] = useState([]);
    const [partnerId, setPartnerId] = useState(null);
    const [gender, setGender] = useState('F');
    const [name, setName] = useState("");
    const [specialities, setSpecialities] = useState("");
    const [cpf, setCpf] = useState("");
    const [crm, setCrm] = useState("");
    const [phone, setPhone] = useState("");
    const [cepAddress, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPass, setConfPass] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorConf, setErrorConf] = useState("");
    const [errorSpec, setErrorSpec] = useState("");
    const [errorCpf, setErrorCpf] = useState("");
    const [errorCrm, setErrorCrm] = useState("");
    const [loading, setLoading] = useState(false);

    const [path, setPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
    const [file, setFile] = useState({ fileName: "" });


    const handleCepChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let val = value;
        setCep(val);
        if (val.length === 9) {
            cep(value).then(function (endereco) {
                setCity(endereco.city);
                setAddress(endereco.street + ", " + endereco.neighborhood);
                setState(endereco.state);
                setCountry("Brasil");
            });
        }
    };

    const handleNameChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setName(value);
        if (value.length === 0) {
            // input vazio
            setErrorName("has-danger");
        }
        else
            setErrorName("");
    };

    const handlePasswordChange = (e) => {
        let value = e.target.value;
        setPassword(value);
    };

    function onBlurPasswordHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorPassword("has-danger");
        }
    }

    const handleConfPassChange = (e) => {
        let value = e.target.value;
        setConfPass(value);
    };

    function onBlurConfHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorConf("has-danger");
        }
    }


    const handleCpfChange = (e) => {
        e.preventDefault();
        let value = mask(e.target.value, '999.999.999-99');
        setCpf(value);
        console.log(value.length);
        if (value.length === 0) {
            // input vazio
            setErrorCpf("has-danger");
        }
        else
            setErrorCpf("");
    };

    const handleCrmChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setCrm(value);
        if (value.length === 0) {
            // input vazio
            setErrorCrm("has-danger");
        }
        else
            setErrorCrm("");
    };

    const handleSpecChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setSpecialities(value);
        if (value.length === 0) {
            // input vazio
            setErrorSpec("has-danger");
        }
        else
            setErrorSpec("");
    };

    const handleEmailChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setEmail(value);
        if (value.length === 0) {
            // input vazio
            setErrorEmail("has-danger");
        }
        else
            setErrorEmail("");
    };

    useEffect(() => {
        getPartners();
    }, []);

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function checkDay(day) {

        let day_pos = null, days_array = days;

        if (days_array.includes(day)) {
            day_pos = days_array.indexOf(day);
            days_array.splice(day_pos, 1);
        }
        else
            days_array.push(day);

        setDays([...days_array]);

    }

    async function getPartners() {

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/getpartners')
            .then(response => {
                if (response.data.length !== 0)
                    setPartnerId(response.data[0].id || 0);
                setPartners(response.data || []);
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data));
            });
    }

    function validPassword() {
        if (password !== confPass) {
            setErrorPassword("has-danger");
            setErrorConf("has-danger");
            notify("warning", "A senha informada não é igual a senha confirmada!");
            return false;
        }
        else {
            setErrorPassword("");
            setErrorConf("");
            return true;
        }
    }

    function error() {
        if (name.length !== 0 & cpf.length !== 0 & crm.length !== 0 & email.length !== 0 &
            specialities.length !== 0) {
            if (password.length !== 0 & confPass.length !== 0 & validPassword() & cpf.length === 14) {
                return false;
            }
            else {
                notify("warning", "O CPF preenchido é inválido!");
                setErrorCpf("has-danger");
            }
            return true;
        }

        else {
            if (name.length === 0)
                setErrorName("has-danger");
            if (email.length === 0)
                setErrorEmail("has-danger");
            if (cpf.length === 0)
                setErrorCpf("has-danger");
            if (crm.length === 0)
                setErrorCrm("has-danger");
            if (specialities.length === 0)
                setErrorSpec("has-danger");
            if (password.length === 0) {
                setErrorPassword("has-danger");
            }
            else if (confPass.length === 0)
                setErrorConf("has-danger");

            notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
            return true;
        }
    }

    async function createDoctor() {
        const data = {
            name,
            email,
            password,
            phone,
            type_connection: 'local',
            cep: cepAddress,
            address,
            country,
            state,
            city,
            cpf,
            crm,
            gender,
            days,
            partner_id: partnerId,
            specialities: specialities.split(', ')
        };

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/storedoctor', data)
            .then(response => {
                console.log("Médico cadastrado com sucesso");
                notify("success", "Médico cadastrado com sucesso! Em breve você receberá em seu e-mail uma resposta do estabelecimento que escolheu trabalhar");
                authLogin();
            })
            .catch(err => {
                notify("warning", "Erro! Não foi possível realizar o cadastro! Tente mais tarde.");
                console.log('Erro: ' + JSON.stringify(err.response.data));
            });

    }

    function submit() {
        if (!error()) {
            setLoading(true);
            createDoctor();
            setLoading(false);
        }
    }

    async function authLogin() {
        await api
            .post("/sessions", {
                email,
                password
            })
            .then(response => {
                storePicture(response.data.id, response.data.token);
                //REALIZAR O TRATAMENTO DE ERROS AQUI
            })
            .catch(err => {
                console.log("Erro: " + JSON.stringify(err));
            });
    }

    async function storePicture(id, token) {

        let data = new FormData();
        data.append('profile_pic', file);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/sessions/' + id + "/image", data, config)
            .then(response => {
                console.log("Foto atualizada com sucesso");
                props.history.push('/login');
            })
            .catch(error => {
                console.log('Erro: ' + JSON.stringify(error.response.data));
            });

    }

    let select_options = null;
    if (partners) {
        select_options = partners.map((item, index) => {
            return (
                item.address !== null ?
                    <option value={item.id} key={index}>{item.name + " - " + item.address}</option>
                    :
                    <option value={item.id} key={index}>{item.name}</option>
            );
        });
    }

    return (

        <>
            <div className="wrapper">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <div class="h-100 row align-items-center"
                // className="main-panel"
                // data={"blue"}
                >

                    <CardBody  >
                        <div >
                            <CardBody  >
                                <Row className="m-2">
                                    <Col md="2">
                                        <Card className="text-center">
                                            <CardBody>
                                                <div className="author">
                                                    <img
                                                        alt="..."
                                                        className="med-mais-logo"
                                                        src={require("../assets/img/icon_med_blue.png")}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="7">
                                        <Card>
                                            <CardBody   >
                                                <Form>
                                                    <Row>
                                                        <Col className="pr-md-1" md="4">
                                                            <FormGroup className={errorName}>
                                                                <label>Nome*</label>
                                                                <Input
                                                                    onChange={handleNameChange}
                                                                    placeholder="Nome completo"
                                                                    type="text"
                                                                    value={name}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="px-md-1" md="4">
                                                            <FormGroup className={errorCpf}>
                                                                <label>CPF*</label>
                                                                <Input
                                                                    onChange={handleCpfChange}
                                                                    placeholder="Cadastro de Pessoa Física"
                                                                    type="text"
                                                                    value={cpf}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="pl-md-1" md="4">
                                                            <FormGroup className={errorCrm}>
                                                                <label>CRM*</label>
                                                                <Input
                                                                    onChange={handleCrmChange}
                                                                    placeholder="Conselho Regional de Medicina"
                                                                    type="text"
                                                                    value={crm}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-md-1" md="12">
                                                            <FormGroup className={errorSpec}>
                                                                <label>Especialidades*</label>
                                                                <Input
                                                                    onChange={handleSpecChange}
                                                                    placeholder="Ex: Cardiologia, Anestesiologia, Dermatologia, ..."
                                                                    type="text"
                                                                    value={specialities}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        loading ?
                                                            <Row className="justify-content-md-center">
                                                                <Col md="auto">
                                                                    <CircularProgress />
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Row>
                                                                <Col className="pr-md-1" md="4">
                                                                    <FormGroup className={errorEmail}>
                                                                        <label>E-mail*</label>
                                                                        <Input
                                                                            onChange={handleEmailChange}
                                                                            placeholder="exemplo@hotmail.com"
                                                                            type="text"
                                                                            value={email}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="px-md-1" md="4">
                                                                    <FormGroup className={errorPassword}>
                                                                        <label>Senha*</label>
                                                                        <Input
                                                                            onChange={handlePasswordChange}
                                                                            onBlur={onBlurPasswordHandle}
                                                                            placeholder="Digite sua senha"
                                                                            type="password"
                                                                            value={password}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="pl-md-1" md="4">
                                                                    <FormGroup className={errorConf}>
                                                                        <label>Confirmar senha*</label>
                                                                        <Input
                                                                            onChange={handleConfPassChange}
                                                                            onBlur={onBlurConfHandle}
                                                                            placeholder="Confirme sua senha"
                                                                            type="password"
                                                                            value={confPass}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                    }
                                                    <Row>
                                                        <Col className="pr-md-1" md="6">
                                                            <FormGroup>
                                                                <label>Telefone</label>
                                                                <Input
                                                                    placeholder="Telefone"
                                                                    type="text"
                                                                    value={phone}
                                                                    onChange={(txt) => setPhone(mask(txt.target.value, '+99(99)99999-9999'))}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="pl-md-1" md="6">
                                                            <FormGroup>
                                                                <label>CEP</label>
                                                                <Input
                                                                    placeholder="CEP"
                                                                    type="text"
                                                                    value={cepAddress}
                                                                    onChange={(txt) => setCep(mask(txt.target.value, '99999-999'))}
                                                                    onBlur={handleCepChange}
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <label>Logradouro</label>
                                                                <Input
                                                                    onChange={(txt) => setAddress(txt.target.value)}
                                                                    placeholder="Avenida, Bairo, Rua, Número..."
                                                                    type="text"
                                                                    value={address}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-md-1" md="4">
                                                            <FormGroup>
                                                                <label>País</label>
                                                                <Input
                                                                    placeholder="País"
                                                                    type="text"
                                                                    value={country}
                                                                    onChange={(txt) => setCountry(txt.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="px-md-1" md="4">
                                                            <FormGroup>
                                                                <label>Estado</label>
                                                                <Input
                                                                    placeholder="Estado"
                                                                    type="text"
                                                                    value={state}
                                                                    onChange={(txt) => setState(txt.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="pl-md-1" md="4">
                                                            <FormGroup>
                                                                <label>Cidade</label>
                                                                <Input
                                                                    placeholder="Cidade"
                                                                    type="text"
                                                                    value={city}
                                                                    onChange={(txt) => setCity(txt.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="exampleSelect1">Local de trabalho</Label>
                                                                <Input
                                                                    type="select"
                                                                    name="select"
                                                                    id="exampleSelect1"
                                                                    onChange={(val) => setPartnerId(val.target.value)}
                                                                >
                                                                    {select_options}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col />
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Dias de trabalho</label>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Dom"}
                                                                        checked={days.includes("Dom")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Domingo
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Seg"}
                                                                        checked={days.includes("Seg")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Segunda
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Ter"}
                                                                        checked={days.includes("Ter")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Terça
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Qua"}
                                                                        checked={days.includes("Qua")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Quarta
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Qui"}
                                                                        checked={days.includes("Qui")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Quinta
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Sex"}
                                                                        checked={days.includes("Sex")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Sexta
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        value={"Sab"}
                                                                        checked={days.includes("Sab")}
                                                                        onChange={(val) => checkDay(val.target.value)}
                                                                    />
                                                    Sábado
                                                    <span className="form-check-sign">
                                                                        <span className="check"></span>
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <label>Sexo</label>
                                                            <div className="ml-3">
                                                                <Row>
                                                                    <FormGroup
                                                                        check
                                                                        inline
                                                                        className="form-check-radio"

                                                                    >
                                                                        <Label className="form-check-label">
                                                                            <Input
                                                                                type="radio"
                                                                                name="exampleRadios1"
                                                                                id="exampleRadios11"
                                                                                value="M"
                                                                                checked={gender === 'M'}
                                                                                onChange={() => setGender('M')}
                                                                            />
                                                            Masculino
                                                            <span className="form-check-sign"></span>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Row>
                                                                <Row>
                                                                    <FormGroup
                                                                        check
                                                                        inline
                                                                        className="form-check-radio"

                                                                    >
                                                                        <Label className="form-check-label">
                                                                            <Input
                                                                                type="radio"
                                                                                name="exampleRadios1"
                                                                                id="exampleRadios12"
                                                                                value="F"
                                                                                checked={gender === 'F'}
                                                                                onChange={() => setGender('F')}
                                                                            />
                                                            Feminino
                                                            <span className="form-check-sign"></span>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                            <CardFooter>
                                                <Button
                                                    className="btn-fill"
                                                    color="info"
                                                    type="submit"
                                                    onClick={submit}
                                                >
                                                    Enviar dados para a clínica
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col md="3">
                                        <Card className="card-user">
                                            <CardBody>
                                                <CardText />
                                                <img
                                                    alt="..."
                                                    className="img-partner"
                                                    src={path}
                                                />
                                            </CardBody>
                                            <CardFooter>
                                                <div className="button-container">
                                                    <p className="description">Anexe uma foto sua segurando a Célula de Identidade de Médico contendo sua CRM, em que seu rosto esteja visível</p>
                                                </div>
                                                <FormGroup>
                                                    <label>Foto</label>
                                                    <FileInput path={setPath} file={setFile} />
                                                </FormGroup>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>

                        </div>

                    </CardBody>

                </div>
            </div>
        </>
    );
}
