import React, { useState, useEffect } from 'react'
import api from '../../../services/api'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
} from "reactstrap";

import {
    Title,
    SubTitle
} from '../styles'

import { mask } from 'remask';

import MaterialTable from 'material-table';

import icone from '../Dashboard_Icons/BuscaDePacientes.svg'
import filtro from '../Dashboard_Icons/Filtro.svg'


const dataAtual = new Date();
const dia = dataAtual.getDate() < '10' ? `0${dataAtual.getDate()}` : dataAtual.getDate();
const mes = dataAtual.getMonth() + 1 < '10' ? `0${dataAtual.getMonth() + 1}` : dataAtual.getMonth() + 1;
function inverteData(data) {

    return data[6] + data[7] + data[8] + data[9] + data[5] + data[3] + data[4] + data[2] + data[0] + data[1];
}



export default function CardPacientsSearches(props) {



    const [filterActivate, setFilterActivate] = useState(false)
    const [minDate, setMinDate] = useState("")
    const [maxDate, setMaxDate] = useState("")
    const [pesquisas, setPesquisas] = useState([])

    async function filtrar(min, max) {
        await api.get(`/getpesquisapacient/${inverteData(min)}/${inverteData(max)}`)
            .then(response => {
                setPesquisas(response.data[0])
                // console.log(inverteData(max))
            })
            .catch(error => {
                console.log('error')
            })

    }
    useEffect(() => {
        filtrar('01-01-2020', `${dia}-${mes}-${dataAtual.getFullYear()}`)
    }, [])
    return (<Card className="card-chart"
        style={{
            borderRadius: '20px',
            boxShadow: '0 4px 4px rgba(0,0,0,0.25)'
        }}>
        <CardHeader>
            <Row>
                <Col sm={10}>
                    <Title>Busca de Pacientes</Title>
                    <Row>
                        <Col>

                            <SubTitle style={{ cursor: 'pointer' }}> <div onClick={() => setFilterActivate(!filterActivate)}>Filtrar <img src={filtro} alt="filtrar" style={{ fill: '#3358F4' }} /> </div></SubTitle>

                        </Col>
                    </Row>
                    {filterActivate ?
                        <Card style={{
                            backgroundColor: '#E5E5E5',
                            borderRadius: '20px',
                            boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
                            width: '120%',
                            marginTop: '10px'
                        }}>
                            <CardHeader><Title>Escolha um intervalo:</Title></CardHeader>
                            <CardBody>
                                <Form onSubmit={e => { e.preventDefault(); }}>
                                    <FormGroup>

                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                            flexFlow: 'row nowrap'
                                        }}>
                                            <div>
                                                <label>Data Mínima</label>
                                                <Input
                                                    style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                    type="datetime"
                                                    placeholder="DD-MM-AAAA"
                                                    value={minDate}
                                                    onChange={(e) => { setMinDate(mask(e.target.value, '99-99-9999')) }}
                                                />
                                            </div>
                                            <span style={{ margin: '0 10px' }}>até</span>
                                            <div>
                                                <label>Data Máxima</label>
                                                <Input
                                                    style={{ backgroundColor: 'rgba(51,88,244,0.5)' }}
                                                    type="datetime"
                                                    placeholder="DD-MM-AAAA"
                                                    value={maxDate}
                                                    onChange={(e) => { setMaxDate(mask(e.target.value, '99-99-9999')) }}
                                                />
                                            </div>
                                            {/* <div> */}


                                            {/* </div> */}
                                        </div>
                                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Button
                                                color="link"
                                                type="button"
                                                onClick={() => {
                                                    setFilterActivate(!filterActivate);
                                                    filtrar(minDate, maxDate);
                                                }}
                                            >
                                                BUSCAR <i className="tim-icons icon-zoom-split" />
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card> : null}
                </Col>
                <Col sm={2}>
                    <div>
                        <img src={icone} alt="icone" style={{ maxHeight: '40px' }} />
                    </div>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <Col xs={12}>
                <MaterialTable

                    columns={[
                        { title: 'REMÉDIOS', field: 'REMEDIOS', align: 'center' },
                        { title: 'VACINAS', field: 'VACINAS', align: 'center' },
                        { title: 'CLÍNICAS', field: 'CLINICAS', align: 'center' }
                    ]}

                    data={pesquisas}

                    options={
                        {
                            showTitle: false,
                            header: true,
                            search: false,
                            paging: false,
                            emptyRowsWhenPaging: false,
                            filtering: false,
                            grouping: false,
                            toolbar: false,

                            headerStyle: {
                                color: 'rgba(0,0,0,0.5)',
                                fontWeight: '300',
                                fontFamily: "'Poppins' sans-serif",
                                fontSize: '15px'
                            }
                        }
                    }

                    style={{
                        boxShadow: '0 0 0 black',
                        fontSize: '25px',
                        fontWeight: '550',
                        color: 'rgba(0,0,0,0.5)'
                    }}

                />
            </Col>
        </CardBody>
    </Card>
    )
}