import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import CircularProgress from '@material-ui/core/CircularProgress';

import { token } from '../../utils'
import api from '../../services/api'
import { mask, unMask } from 'remask'
function VaccinesCreateTable(props) {

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState("");
    const [dose, setDose] = useState("");
    const [errorDose, setErrorDose] = useState("");
    const [description, setDescription] = useState("");
    const [errorDesc, setErrorDesc] = useState("");
    const [gender, setGender] = useState(null);
    const [startRecomended, setStartRecomend] = useState("");
    const [repeatsAt, setRepeatsAt] = useState("");
    const [gapNextDose, setGapNextDose] = useState("");
    const [limitBottom, setLimitBottom] = useState("");
    const [errorBottom, setErrorBottom] = useState("");
    const [limitTop, setLimitTop] = useState("");
    const [errorTop, setErrorTop] = useState("");
    const [price, setPrice] = useState("");
    const [errorPrice, setErrorPrice] = useState("");
    const [stock, setStock] = useState("");
    const [errorStock, setErrorStock] = useState("");
    const notificationAlert = useRef(null);
    const [free, setFree] = useState(false);
    const [loading, setLoading] = useState(false)

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function error() {
        if (props.isPartner) {
            if (price.length !== 0 & stock.length !== 0 & name.length !== 0) {
                return false;
            }
            else {
                if (name.length === 0) {
                    notify("warning", "É necessário selecionar uma vacina!");
                }
                else {
                    if (price.length === 0) {
                        setErrorPrice("has-danger");
                    }
                    else
                        setErrorPrice("")
                    if (stock.length === 0) {
                        setErrorStock("has-danger");
                    }
                    else
                        setErrorStock("")
                    notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
                }

                return true;
            }
        }
        else {
            if (name.length !== 0 & dose.length !== 0 & description.length !== 0 &
                limitBottom.length !== 0 & limitTop.length !== 0) {
                return false;
            }
            else {
                if (name.length === 0) {
                    setErrorName("has-danger")
                }
                else
                    setErrorName("")
                if (dose.length === 0)
                    setErrorDose("has-danger")
                else
                    setErrorDose("")
                if (description.length === 0)
                    setErrorDesc("has-danger")
                else
                    setErrorDesc("")
                if (limitTop.length === 0)
                    setErrorTop("has-danger")
                else
                    setErrorTop("has-danger")
                if (limitBottom.length === 0)
                    setErrorBottom("has-danger")
                else
                    setErrorBottom("")

                notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
                return true;
            }
        }
    }

    useEffect(() => {

        if (props.vaccineId) {
            setLoading(true)
            if (props.isPartner) {
                if (props.isEditable)
                    getPartnerVac(props.vaccineId)
                else
                    getVaccine(props.vaccineId)
            }
            else
                getVaccine(props.vaccineId)
            setLoading(false)
        }

    }, [props.vaccineId]);

    async function getPartnerVac(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/partnervac/' + id, config)
            .then(response => {
                setName(response.data.name || "")
                setDose(response.data.dose || "")
                setDescription(response.data.description || "")
                setGender(response.data.recomended_gender)
                setStartRecomend(response.data.start_recomend || "")
                setRepeatsAt(response.data.repeats_at || "")
                setGapNextDose(response.data.gap_next_dose || "")
                setLimitBottom(response.data.limit_bottom || "")
                setLimitTop(response.data.limit_top || "")
                setPrice(response.data.price || "")
                setStock(response.data.stock || "")
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar dados da vacina selecionada! Tente novamente mais tarde.")
            })

    }

    async function getVaccine(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/vaccine/' + id, config)
            .then(response => {

                setName(response.data.name || "")
                setDose(response.data.dose || "")
                setDescription(response.data.description || "")
                setGender(response.data.recomended_gender)
                setStartRecomend(response.data.start_recomend || "")
                setRepeatsAt(response.data.repeats_at || "")
                setGapNextDose(response.data.gap_next_dose || "")
                setLimitBottom(response.data.limit_bottom || "")
                setLimitTop(response.data.limit_top || "")
                setPrice(response.data.price || "")
                setStock(response.data.stock || "")
                if (response.data.price === 0)
                    setFree(true)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar dados da vacina selecionada! Tente novamente mais tarde.")
            })

    }

    async function storePartnerVac() {

        const data = {
            stock,
            price,
            vaccine_id: props.vaccineId
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/partnervac', data, config)
            .then(response => {
                console.log("Vacina adicionada com sucesso")
                notify("success", "Vacina adicionada com sucesso!");
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                if (err.response.status === 500) {
                    notify("warning", "Erro! Não é possível adicionar essa vacina ao seu estoque pois você já possui!")
                }
            })

    }

    async function editPartnerVac(id) {

        const data = {
            stock,
            price
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/partnervac/' + id, data, config)
            .then(response => {
                console.log("Vacina editada com sucesso")
                notify("succes", "Vacina editada com sucesso!");
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro! Não foi possível editar a vacina selecionada! Tente novamente mais tarde.!")
            })

    }

    async function editVaccine(id) {

        const data = {
            name,
            description,
            dose,
            start_recomend: startRecomended,
            limit_bottom: limitBottom,
            limit_top: limitTop,
            gap_next_dose: gapNextDose,
            repeats_at: repeatsAt,
            recomended_gender: gender
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/vaccine/' + id, data, config)
            .then(response => {
                console.log("Vacina editada com sucesso")
                notify("success", "Vacina editada com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro! Não foi possível editar a vacina selecionada! Tente novamente mais tarde.")
            })

    }

    async function createVaccine() {

        const data = {
            name,
            dose,
            description,
            gender,
            start_recomend: startRecomended,
            repeats_at: repeatsAt,
            gap_next_dose: gapNextDose,
            limit_bottom: limitBottom,
            limit_top: limitTop,
            recomended_gender: gender
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/vaccine', data, config)
            .then(response => {
                console.log("Vacina criada com sucesso")
                notify("success", "Vacina criada com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                if (err.response.status === 500) {
                    notify("warning", "Erro! Não é possível adicionar a vacina pois ela já existe!")
                }
            })

    }

    function submit() {
        if (!error()) {
            setLoading(true)
            if (props.isPartner) {

                if (props.vaccineId) {

                    if (props.isEditable)
                        editPartnerVac(props.vaccineId)
                    else
                        storePartnerVac()
                }
            }
            else if (props.vaccineId)
                editVaccine(props.vaccineId)
            else
                createVaccine()
            setLoading(false)
        }

    }

    return (
        <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <h5 className="title">Adicione uma vacina ao seu estoque</h5>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <p className="category">Informações gerais da vacina</p>
                                {
                                    loading ?
                                        <Row className="justify-content-md-center">
                                            <Col md="auto">
                                                <CircularProgress />
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="pr-md-1" md="6">
                                                <FormGroup className={errorName}>
                                                    <label>Nome*</label>
                                                    <Input
                                                        placeholder="Nome da vacina"
                                                        type="text"
                                                        disabled={props.disabled}
                                                        value={name}
                                                        onChange={(txt) => {
                                                            setName(txt.target.value)
                                                            if (name.length !== 0)
                                                                setErrorName("")
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md="6">
                                                <FormGroup className={errorDose}>
                                                    <label>Dose*</label>
                                                    <Input
                                                        placeholder="Dose da vacina"
                                                        type="number"
                                                        disabled={props.disabled}
                                                        value={dose}
                                                        onChange={(txt) => {
                                                            setDose(txt.target.value)
                                                            if (dose.length !== 0)
                                                                setErrorDose("")
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                }
                                <Row>
                                    <Col md="8">
                                        <FormGroup className={errorDesc}>
                                            <label>Descrição*</label>
                                            <Input
                                                cols="80"
                                                placeholder="Descreva aqui alguma informação pertinente sobre a vacina nova que deseja criar"
                                                rows="4"
                                                type="textarea"
                                                disabled={props.disabled}
                                                value={description}
                                                onChange={(txt) => {
                                                    setDescription(txt.target.value)
                                                    if (description.length !== 0) {
                                                        setErrorDesc("")
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4" className="mb-3 pl-md-1">
                                        <label>Sexo recomendado </label>
                                        <Row className="d-flex justify-content-center" >
                                            <FormGroup
                                                check
                                                inline
                                                className="form-check-radio"
                                                disabled={props.disabled}
                                            >
                                                <Label className="form-check-label">
                                                    <Input
                                                        type="radio"
                                                        name="exampleRadios1"
                                                        id="exampleRadios11"
                                                        value="M"
                                                        onChange={() => setGender('M')}
                                                        checked={gender === 'M'}
                                                    />
                                                            Masculino
                                                            <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup
                                                check
                                                inline
                                                className="form-check-radio"
                                                disabled={props.disabled}
                                            >
                                                <Label className="form-check-label">
                                                    <Input
                                                        type="radio"
                                                        name="exampleRadios1"
                                                        id="exampleRadios12"
                                                        value="F"
                                                        onChange={() => setGender('F')}
                                                        checked={gender === 'F'}
                                                    />
                                                            Feminino
                                                            <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup
                                                check
                                                inline
                                                className="form-check-radio"
                                                disabled={props.disabled}
                                            >
                                                <Label className="form-check-label">
                                                    <Input
                                                        type="radio"
                                                        name="exampleRadios1"
                                                        id="exampleRadios13"
                                                        value={"I"}
                                                        onChange={() => setGender(null)}
                                                        checked={gender === null}
                                                    />
                                                            Independe
                                                            <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="4">
                                        <FormGroup>
                                            <label>Mês recomendado</label>
                                            <Input
                                                placeholder="Idade em meses que a vacina é recomendada"
                                                type="text"
                                                disabled={props.disabled}
                                                value={startRecomended}
                                                onChange={(txt) => setStartRecomend(txt.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="px-md-1" md="4">
                                        <FormGroup>
                                            <label>Intervalo de repetição</label>
                                            <Input
                                                placeholder="Intervalo em meses que a vacina volta a ser recomendada"
                                                type="text"
                                                disabled={props.disabled}
                                                value={repeatsAt}
                                                onChange={(txt) => setRepeatsAt(txt.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-md-1" md="4">
                                        <FormGroup>
                                            <label>Intervalo para a próxima dose</label>
                                            <Input
                                                placeholder="Intervalo em meses que a próxima dose é recomendada"
                                                type="text"
                                                disabled={props.disabled}
                                                value={gapNextDose}
                                                onChange={(txt) => setGapNextDose(txt.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup className={errorBottom}>
                                            <label>Margem inferior*</label>
                                            <Input
                                                placeholder="Margem inferior para visualização da recomendação em meses"
                                                type="text"
                                                disabled={props.disabled}
                                                value={limitBottom}
                                                onChange={(txt) => {
                                                    setLimitBottom(txt.target.value)
                                                    if (limitBottom.length !== 0)
                                                        setErrorBottom("")
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-md-1" md="6">
                                        <FormGroup className={errorTop}>
                                            <label>Margem superior*</label>
                                            <Input
                                                placeholder="Margem superior para visualização da recomendação em meses"
                                                type="text"
                                                disabled={props.disabled}
                                                value={limitTop}
                                                onChange={(txt) => {
                                                    setLimitTop(txt.target.value)
                                                    if (limitTop.length !== 0)
                                                        setErrorTop("")
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    props.isPartner ?
                                        <>
                                            <p className="category">Informações específicas da vacina no estabelecimento</p>
                                            <Row>

                                                <Col className="pr-md-1" md="2">
                                                    <label>Tipo de Produto</label>
                                                    <div className="ml-2">
                                                        <FormGroup
                                                            check
                                                            inline
                                                        > <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    // name="exampleRadios1"
                                                                    // id="exampleRadios11"
                                                                    value="gratis"
                                                                    checked={free}
                                                                    onChange={() => {
                                                                        setFree(!free)
                                                                        setPrice(0.00)
                                                                    }}
                                                                />
                                                Amostra Grátis
                                                <span className="form-check-sign"></span>
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <Col className="pr-md-1" md="5">
                                                    <FormGroup className={errorPrice}>
                                                        <label>Preço*</label>
                                                        <Input
                                                            placeholder="R$ 0.00"
                                                            type="text"
                                                            value={price}
                                                            disabled={free}
                                                            onChange={(txt) => {
                                                                setPrice(mask(unMask(txt.target.value), ['9', '99', '9.99', '99.99', '999.99', '9999.99', '99999.99', '999999.99', '9999999.99', '99999999.99']))
                                                                if (price.length !== 0 & !free)
                                                                    setErrorPrice("")
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pl-md-1" md="5">
                                                    <FormGroup className={errorStock}>
                                                        <label>Estoque*</label>
                                                        <Input
                                                            placeholder="Quantidade da vacina no estoque"
                                                            type="number"
                                                            value={stock}
                                                            onChange={(txt) => {
                                                                setStock(txt.target.value)
                                                                if (stock.length !== 0)
                                                                    setErrorStock("")
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button
                                className="btn-fill"
                                color="info"
                                type="submit"
                                onClick={submit}
                            >
                                Salvar
                  </Button>
                        </CardFooter>
                    </Card>
                </Col>

            </Row>
        </>
    );

}

export default VaccinesCreateTable;