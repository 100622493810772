/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import DocAllTable from "./PartnerAllTable"
import DocPendTable from "./PartnerPendTable"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    ButtonGroup
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import classNames from "classnames";
import { token, doctorId } from '../../utils'
import api from '../../services/api'

function Doctors() {

    const notificationAlert = useRef(null);

    const [productType, setProductType] = useState("Pendentes");
    const [days, setDays] = useState([]);
    const [partDocId, setPartDocId] = useState(null);
    const [partnerId, setPartnerId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [cepAddress, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [path, setPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
    const [cnpj, setCnpj] = useState("");
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState(0);
    const [priority, setPriority] = useState(false);
    const [loading, setLoading] = useState(false)

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function checkDay(day) {

        let day_pos = null

        if (days.includes(day)) {
            day_pos = days.indexOf(day)
            days.splice(day_pos, 1)
        }
        else
            days.push(day)

        setDays([...days])
    }


    async function getPartner(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/partner/' + id, config)
            .then(response => {
                setPartnerId(response.data.id || null)
                setName(response.data.name || "")
                setPhone(response.data.phone || "");
                setState(response.data.state || "");
                setAddress(response.data.address || "");
                setCep(response.data.cep || "");
                setCity(response.data.city || "");
                setCountry(response.data.country || "");
                setEmail(response.data.email || "");
                setCnpj(response.data.cnpj || "");
                setLat(response.data.lat || "")
                setLong(response.data.lng || "")
                setCategory(response.data.category || 0)
                setWhatsapp(response.data.whatsapp_phone || "")
                setDescription(response.data.description || "")
                setPriority(response.data.is_priority || false)
                setDays([])
                setLoading(false)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function getPartDoc(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/getpartner/' + id, config)
            .then(response => {
                setPartnerId(response.data.id || null)
                setName(response.data.name || "")
                setPhone(response.data.phone || "");
                setState(response.data.state || "");
                setAddress(response.data.address || "");
                setCep(response.data.cep || "");
                setCity(response.data.city || "");
                setCountry(response.data.country || "");
                setEmail(response.data.email || "");
                setCnpj(response.data.cnpj || "");
                setLat(response.data.lat || "")
                setLong(response.data.lng || "")
                setCategory(response.data.category || 0)
                setWhatsapp(response.data.whatsapp_phone || "")
                setDescription(response.data.description || "")
                setPriority(response.data.is_priority || false)
                setDays(response.data.days || [])
                setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
                setLoading(false)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function vinculatePartner(id, accept) {

        const data = {
            partner_id: id,
            doctor_id: doctorId(),
            is_accepted: accept,
            days
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/clinicDoctor', data, config)
            .then(response => {
                console.log("Médico vinculado com sucesso")
                if (accept === true)
                    notify("success", "Vinculação realizada com sucesso!")
                else if (accept === null)
                    notify("success", "Aguarde a aprovação do estabelecimento!")
                else
                    notify("success", "Vinculação recusada com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                notify("warning", "Ocorreu um erro! Por favor, tente mais tarde.")
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function editPartner(id) {

        const data = { days }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/clinicDoctor/' + id, data, config)
            .then(response => {
                console.log("Médico atualizado com sucesso")
                notify("success", "Dias de trabalho atualizados com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                notify("warning", "Ocorreu um erro! Por favor, tente mais tarde.")
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    function submit() {

        if (partnerId) {
            setLoading(true)
            if (isEditable)
                editPartner(partDocId)
            else if (isPending)
                vinculatePartner(partnerId, true)
            else
                vinculatePartner(partnerId, null)
            setLoading(false)
        }

    }

    function callPartner(id, edit, pending) {
        setIsEditable(edit)
        setIsPending(pending)
        setLoading(true)
        if (!edit && !pending) {
            getPartner(id)
        }

        else getPartDoc(id)
    }

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: productType === "Pendentes"
                                        })}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={() => setProductType("Pendentes")}
                                    >
                                        <input
                                            defaultChecked
                                            className="d-none"
                                            name="options"
                                            type="radio"
                                        />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Pendentes
                          </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bag-16" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: productType === "Todos"
                                        })}
                                        onClick={() => setProductType("Todos")}
                                    >
                                        <input
                                            className="d-none"
                                            name="options"
                                            type="radio"
                                        />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Todos
                          </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    {productType === "Pendentes" ?
                        <>

                            <DocPendTable allParts={false} pendingParts={true} select={(id) => callPartner(id, false, true)} />

                        </>
                        :
                        <>

                            <DocAllTable pendingParts={false} allParts={true} select={(id) => callPartner(id, false, false)} />
                        </>

                    }
                </Card>
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Edite seu perfil</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    {
                                        loading ?
                                            <Row className="justify-content-md-center">
                                                <Col md="auto">
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className="pr-md-1" md="6">
                                                    <FormGroup>
                                                        <label>Nome*</label>
                                                        <Input
                                                            placeholder="Nome"
                                                            type="text"
                                                            value={name}
                                                            disabled={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pl-md-1" md="6">
                                                    <FormGroup>
                                                        <label htmlFor="exampleInputEmail1">
                                                            E-mail*
                          </label>
                                                        <Input placeholder="exemplo@email.com"
                                                            type="email"
                                                            value={email}
                                                            disabled={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                    }
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label>CNPJ*</label>
                                                <Input placeholder="CNPJ"
                                                    type="text"
                                                    value={cnpj}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="6">
                                            <FormGroup>
                                                <label>CEP</label>
                                                <Input placeholder="CEP"
                                                    type="text"
                                                    value={cepAddress}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Logradouro</label>
                                                <Input
                                                    placeholder="Avenida, Bairo, Rua, Número..."
                                                    type="text"
                                                    value={address}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>País</label>
                                                <Input
                                                    placeholder="País"
                                                    type="text"
                                                    value={country}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup>
                                                <label>Estado</label>
                                                <Input
                                                    placeholder="Estado"
                                                    type="text"
                                                    value={state}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Cidade</label>
                                                <Input
                                                    placeholder="Cidade"
                                                    type="text"
                                                    value={city}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>Latitude*</label>
                                                <Input
                                                    placeholder="-99.999999"
                                                    type="text"
                                                    value={lat}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup>
                                                <label>Longitude*</label>
                                                <Input
                                                    placeholder="99.999999"
                                                    type="text"
                                                    value={long}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Tipo de produto*</label>
                                                <Input type="select"
                                                    name="select"
                                                    id="exampleSelect1"
                                                    value={category}
                                                    disabled={true}
                                                >
                                                    <option value={0} >Produto comercial</option>
                                                    <option value={1} >Amostra grátis</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>WhatsApp</label>
                                                <Input
                                                    placeholder="Número do WhatsApp"
                                                    type="text"
                                                    value={whatsapp}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Telefone</label>
                                                <Input
                                                    placeholder="Número de Telefone"
                                                    type="text"
                                                    value={phone}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <label>Descrição</label>
                                                <Input
                                                    cols="80"
                                                    placeholder="Descreva aqui os serviços prestados pela empresa e outras informações importantes..."
                                                    rows="4"
                                                    type="textarea"
                                                    value={description}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <label>Tem prioridade</label>
                                            <div className="ml-3">
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"
                                                        disabled={true}
                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios11"
                                                                value="Sim"
                                                                checked={priority}
                                                                disabled={true}
                                                            />
                                                            Sim
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"
                                                        disabled={true}
                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios12"
                                                                value="Não"
                                                                checked={!priority}
                                                                disabled={true}
                                                            />
                                                            Não
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label>Dias de trabalho</label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Dom"}
                                                        checked={days.includes("Dom")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Domingo
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Seg"}
                                                        checked={days.includes("Seg")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Segunda
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Ter"}
                                                        checked={days.includes("Ter")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Terça
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Qua"}
                                                        checked={days.includes("Qua")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Quarta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Qui"}
                                                        checked={days.includes("Qui")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Quinta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Sex"}
                                                        checked={days.includes("Sex")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Sexta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Sab"}
                                                        checked={days.includes("Sab")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Sábado
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    className="btn-fill"
                                    color="info"
                                    type="submit"
                                    onClick={submit}
                                >
                                    {isPending ? "Aceitar" : "Salvar"}
                                </Button>
                                {
                                    isPending
                                        ?
                                        <Button
                                            className="btn-fill"
                                            color="danger"
                                            type="submit"
                                            onClick={() => vinculatePartner(partnerId, false)}
                                        >
                                            Recusar
                                        </Button>
                                        : null
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <CardText />
                                <img
                                    alt="..."
                                    className="img-partner"
                                    src={path}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="button-container">
                                    <p className="description">{name}</p>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <DocAllTable allParts={false} edit={(id, part_doc_id) => {
                    callPartner(id, true, false)
                    setPartDocId(part_doc_id)
                }
                } />
            </div>
        </>
    );

}

export default Doctors;
