/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";

// core components
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { Link } from 'react-router-dom';

import api from "../services/api";
import { login, token, userId, partnerId, doctorId } from '../utils';

import CircularProgress from '@material-ui/core/CircularProgress';

var ps;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      email: "",
      password: "",
      loading: false,
    };
  }
  notify(situation, text) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),

      type: situation,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  authLogin = async () => {
    this.setState({ loading: true });
    if (this.state.password.length === 0 || this.state.email.length === 0) {
      this.notify("warning", "Preencha o email e a senha para iniciar a sessão!");
    }
    else {
      await api
        .post("/sessions", {
          email: this.state.email,
          password: this.state.password
        })
        .then(response => {
          if (response.data.token !== undefined && response.data.user_type !== "Pacient") {
            login(response.data.token, response.data.user_type, response.data.id);
            api.defaults.headers.common["Authorization"] = response.data.token;
            if (response.data.user_type === "Partner") {
              this.getUser();
              this.props.history.push('/partner/user-profile');
            }

            else if (response.data.user_type === "Admin")
              this.props.history.push('/admin/dashboard');

            else if (response.data.user_type === "Doctor") {
              this.getUser();
              this.props.history.push('/doctor/user-profile');
            }
            // this.notify("success", "Sessão autorizada!")
          }

        })
        .catch(err => {
          console.log("Erro: " + JSON.stringify(err));
          this.notify("warning", "Erro! Sessão não autorizada!");
        });

    }

    this.setState({ loading: false });
  };

  getUser = async () => {

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    };

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.get('/user/' + userId(), config)
      .then(response => {
        if (response.data.user_type === "Partner")
          partnerId(response.data.id || 0);
        else if (response.data.user_type === "Doctor")
          doctorId(response.data.id || 0);

      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data));
      });
    this.setState({ loading: false });
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <div className="react-notification-alert-container">
              <NotificationAlert ref="notificationAlert" />
            </div>
            <div className="login">
              <Card className="card-user login-card">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <img
                      alt="..."
                      className="med-mais-logo"
                      src={require("../assets/img/icon_med_blue.png")}
                    />
                  </div>
                  <div className="card-description">
                    <Form>
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <Input placeholder="exemplo@email.com" type="email" onChange={(txt) => this.setState({ email: txt.target.value })} />
                      </FormGroup>
                      <FormGroup>
                        <label>Senha</label>
                        <Input placeholder="Digite sua senha" type="password" onChange={(txt) => this.setState({ password: txt.target.value })} />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Link to="/forgotpassword">Esqueci minha senha</Link>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
                <CardFooter>
                  {
                    this.state.loading ?
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <CircularProgress />
                        </Col>
                      </Row>
                      :
                      <>
                        <div className="button-container">
                          <Button className="btn-fill" color="info" type="submit" onClick={this.authLogin}>
                            Entrar
                   </Button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button className="btn-link" size="sm" onClick={() => this.props.history.push('/register')}>
                            Cadastre-se como um médico
                 </Button>
                        </div>
                      </>
                  }

                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}
document.body.classList.add("white-content");
