import React, { useEffect, useState, useRef, forwardRef } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';
import NotificationAlert from "react-notification-alert";
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';


import { token } from '../../utils'
import api from '../../services/api'

function DocPendTable(props) {

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const notificationAlert = useRef(null);
  const [size, setPageSize] = useState(5);

  const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  }


  function notify(id) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div >
          <div>
            <div className="text-center">
              Deseja deletar o médico selecionado?
                  </div>

            <div className="d-flex justify-content-center">
              <Button
                className="btn-link"
                color="light"
                type="submit"
                onClick={() => {
                  // console.log(id)
                  deleteDoctor(id)
                }
                }
              >
                Sim
                    </Button>
            </div>
          </div>
        </div>
      ),

      type: "info",
      icon: "tim-icons",
      autoDismiss: false
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (props.pendingDocs) {
      setTitle("Lista Dos Médicos Que Desejam Entrar Em Sua Equipe")
      setSubtitle("Visualize, aceite ou recuse um médico à sua equipe")
    }
    else {
      setTitle("Lista Dos Médicos De Sua Equipe")
      setSubtitle("Visualize, edite ou exclua os médicos de sua equipe")
    }
  }, [props.pendingDocs]);

  function selectDoctor(id) {
    props.select(id)
  }


  const getPendingDocs = async (query) => {
    return new Promise(resolve => {
      const config = {
        headers: { Authorization: `Bearer ${token()}` }
      }
      setPageSize(query.pageSize)
      if (query.search) {
        api.get(`/searchpenddocs?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(res => {
            
            resolve({
              data: res.data.data,
              page: res.data.page - 1,
              totalCount: res.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
      else {
        api.get(`/getpenddocs?page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(response => {
            resolve({
              data: response.data.data,
              page: response.data.page - 1,
              totalCount: response.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
    })
  }

  const getPartnerDocs = async (query) => {
    return new Promise(resolve => {
      const config = {
        headers: { Authorization: `Bearer ${token()}` }
      }
      setPageSize(query.pageSize)
      if (query.search) {
        api.get(`/searchpartdoc?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(res => {
            resolve({
              data: res.data.data,
              page: res.data.page - 1,
              totalCount: res.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
      else {
        api.get(`/clinicDoctor?page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(response => {
            resolve({
              data: response.data.data,
              page: response.data.page - 1,
              totalCount: response.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
    })

  }


  function editDoctor(id, part_doc_id) {
    props.edit(id, part_doc_id)
  }

  async function deleteDoctor(id) {

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    await api.delete('/clinicDoctor/' + id, config)
      .then(response => {
        notify("success", "Médico excluído com sucesso!");
        console.log("Médico foi excluído da equipe com sucesso!")
        window.location.reload();
      })
      .catch(error => {
        notify("warning", "Erro ao excluir o médico da equipe!");
        console.log("Erro ao excluir o médico da equipe! " + JSON.stringify(error.response.data))
      })
  }

  return (
    <Card className="card-plain">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        <CardSubtitle className="category">{subtitle}</CardSubtitle>
      </CardHeader>
      <CardBody>
        <MaterialTable
          title="Lista de Todos os Medicamentos"
          style={{ backgroundColor: "#f5f6fa" }}
          columns={[
            {
              title: '', field: 'icon', render: (rowData) => (
                props.allDocs === true || props.pendingDocs === true ?
                  <>
                    <Button
                      color="link"
                      type="button"
                      onClick={() => selectDoctor(rowData.id)}
                    >
                      <i className={"tim-icons icon-single-02"} />
                    </Button>
                  </>
                  :
                  <>
                    <Button
                      color="link"
                      type="button"
                      onClick={() => editDoctor(rowData.id, rowData.part_doc_id)}
                    >
                      <i className="tim-icons icon-pencil" />
                    </Button>
                    <Button
                      color="link"
                      type="button"
                      onClick={() => notify(rowData.part_doc_id)}
                    >
                      <i className="tim-icons icon-trash-simple" />
                    </Button>
                  </>
              )
            },
            { title: 'Nome', field: 'name', width: 20, align: 'center' },
            {
              title: 'Especialidades', field: 'specialities', with: 10, align: 'center',
              render: rowData =>
                <label style={{ fontSize: 14, color: 'black' }}>{rowData.specialities.join(", ")}</label>
            },
            {
              title: 'Locais de Trabalho', field: 'clinics', with: 10, align: 'center',
              render: rowData =>
                <label style={{ fontSize: 14, color: 'black' }}>{rowData.clinics.join(", ")}</label>
            }
          ]}
          options={{
            showTitle: false,
            header: true,
            headerStyle: {
              color: '#000',
              fontWeight: 'bold',
              backgroundColor: '#f5f6fa',
              fontFamily: 'Poppins',
            },
            padding: 'default',
            initialPage: 0,
            pageSizeOptions: [1, 2, 5, 10, 15],
            pageSize: size,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              color: '#000',
              backgroundColor: (30, 30, 47, 0.3),
            },
          }}
          localization={{

            body: {
              emptyDataSourceMessage: 'Sem médicos para mostrar',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Procurar'
            },
          }}
          data={query =>          
              props.pendingDocs ?
                getPendingDocs(query)
                :
                getPartnerDocs(query)
          }
          icons={tableIcons}
        />
      </CardBody>
    </Card>
  )

}

export default DocPendTable;