/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import MedicinesTable from '../Medicines/components/MedicinesTable';
import VaccinesTable from '../Vaccines/VaccinesTable';

import classNames from "classnames";

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Row,
    Col,
  } from "reactstrap";


function AllProductsPartner() {
    const [productType, setProductType] = useState("Medicines");

    return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: productType === "Medicines"
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setProductType("Medicines")}
                      >
                        <input
                          defaultChecked
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Medicamentos
                          </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-bag-16" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: productType === "Vaccines"
                        })}
                        onClick={() => setProductType("Vaccines")}
                      >
                        <input
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Vacinas
                          </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              {productType === "Medicines" ? <MedicinesTable remove={true} /> : <VaccinesTable remove={true} />}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );

}

export default AllProductsPartner;
