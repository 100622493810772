import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'

export default function WorkPlacesTable(props) {

    const [rows, setRows] = useState([]);

    useEffect(() => {
        loadRows()
    }, [props.workPlaces]);

    function loadRows() {

        if (
            props.workPlaces &&
            props.workPlaces.length !== 0
        ) {
            setRows(props.workPlaces.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.name_address || ''}</td>
                        <td>{item.days.join(', ') || ''}</td>
                        <td>{item.is_accepted === 1 ? "Aprovado" : item.is_accepted === 0 ? "Recusado" : "Pendente"}</td>
                    </tr>
                )
            }))
        } else return null
    }


    return (
        <div style={{
            maxHeight: '473px',
            overflowY: 'auto'
        }}>
            <Table className="tablesorter">
                <thead className="text-primary">
                    <tr>
                        <th>Nome</th>
                        <th>Dias de trabalho</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    )

}