/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { token } from '../../utils'

import api from '../../services/api';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  FormGroup,
  Form,
  CardFooter,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";

import PacientsTable from "./PacientsTable.jsx";

import CircularProgress from '@material-ui/core/CircularProgress';



function DoctorPacients() {

  const [gender, setGender] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cepAddress, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [birth, setBirth] = useState("");
  const [cpf, setCpf] = useState("");
  const [healthInsurence, setHealthInsurence] = useState("");
  const [path, setPath] = useState("https://i.ytimg.com/vi/L1tx-wAI6Nw/maxresdefault.jpg");
  // const [file, setFile] = useState({ fileName: "" });
  const [loading, setLoading] = useState(false)

  async function getPacient(id) {
    setLoading(true)

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.get('/pacients/' + id, config)
      .then(response => {

        const date = response.data.birth.split('-');

        setBirth(date[2][0] + date[2][1] + "/" + date[1] + "/" + date[0]);
        setName(response.data.name || "");
        setPhone(response.data.phone || "");
        setState(response.data.state || "");
        setAddress(response.data.address || "");
        setCep(response.data.cep || "");
        setEmail(response.data.email || "");
        setCity(response.data.city || "");
        setCountry(response.data.country || "");
        setHealthInsurence(response.data.health_insurence || "");
        setCpf(response.data.cpf || "");
        setGender(response.data.gender || "")

        setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
        setLoading(false)
        // console.log(response.data.path)
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
      })

  }

  return (
    <>

      <div className="content">
        <PacientsTable select={getPacient} />
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Visualize os dados de seu paciente</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Nome</label>
                        <Input
                          disabled={true}
                          placeholder="Nome completo do paciente"
                          type="text"
                          value={name}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          E-mail
                                </label>
                        <Input
                          disabled={true}
                          placeholder="exemplo@email.com"
                          type="email"
                          value={email}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Data de nascimento</label>
                        <Input
                          disabled={true}
                          placeholder="dd/mm/aaaa"
                          type="text"
                          value={birth}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          disabled={true}
                          placeholder="Telefone do paciente"
                          type="text"
                          value={phone}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>CEP</label>
                        <Input
                          disabled={true}
                          placeholder="CEP do paciente"
                          type="text"
                          value={cepAddress}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    loading ?
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <CircularProgress />
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Logradouro</label>
                            <Input
                              disabled={true}
                              placeholder="Endereço do paciente"
                              type="text"
                              value={address}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                  }
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>País</label>
                        <Input
                          disabled={true}
                          placeholder="País do paciente"
                          type="text"
                          value={country}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Estado</label>
                        <Input
                          disabled={true}
                          placeholder="Estado do paciente"
                          type="text"
                          value={state}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Cidade</label>
                        <Input
                          disabled={true}
                          placeholder="Cidade do paciente"
                          type="text"
                          value={city}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>CPF</label>
                        <Input
                          disabled={true}
                          placeholder="Cadastro de Pessoa Física"
                          type="text"
                          value={cpf}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Plano de saúde</label>
                        <Input
                          disabled={true}
                          placeholder="Plano de saúde do paciente"
                          type="text"
                          value={healthInsurence}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <label>Sexo</label>
                      <div className="ml-3">
                        <Row>
                          <FormGroup
                            check
                            inline
                            className="form-check-radio"
                            disabled={true}
                          >
                            <Label className="form-check-label">
                              <Input
                                disabled={true}
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios11"
                                value="M"
                                checked={gender === 'M'}
                              />
                                Masculino
                                <span className="form-check-sign"></span>
                            </Label>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup
                            check
                            inline
                            className="form-check-radio"
                            disabled={true}
                          >
                            <Label className="form-check-label">
                              <Input
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios12"
                                value="F"
                                disabled={true}
                                checked={gender === 'F'}
                              />
                                Feminino
                                <span className="form-check-sign"></span>
                            </Label>
                          </FormGroup>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">

                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img-partner"
                      src={path}
                    />

                  </a>
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <h5 className="description">{name}</h5>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );

}

export default DoctorPacients;
