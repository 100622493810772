import React from 'react'

import {
    Row,
    Col
} from "reactstrap";

import CardAllUsers from './Components/CardAllUsers'
import CardPacientsSearches from './Components/CardPacientsSearches'
import CardChart from './Components/CardChart'
import CardUserFilter from './Components/CardUserFilter'

export default function DashboardPacients(props) {
   
  





    return (
        <>
            <Row>
                <Col sm={5}>
                    <CardAllUsers />
                    <CardPacientsSearches />
                </Col>
                <Col sm={7}>
                    <CardChart />
                </Col>
            </Row>
            <Row>
                <Col>
                <CardUserFilter />
                </Col>
            </Row>
        </>
    )

}