/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import DocAllTable from "./DocAllTable"
import DocPendTable from "./DocPendTable"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    ButtonGroup
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import WorkPlacesTable from './WorkPlacesTable'

import classNames from "classnames";
import { token, partnerId } from '../../utils'
import api from '../../services/api'

function Doctors() {

    const notificationAlert = useRef(null);

    const [workIndex, setWorkIndex] = useState(0)
    const [productType, setProductType] = useState("Pendentes");
    const [days, setDays] = useState([]);
    const [workPlaces, setWorkPlaces] = useState([]);
    const [doctorId, setDoctorId] = useState(null);
    const [partDocId, setPartDocId] = useState(null);
    const [gender, setGender] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [name, setName] = useState("");
    const [specialities, setSpecialities] = useState("");
    const [cpf, setCpf] = useState("");
    const [crm, setCrm] = useState("");
    const [phone, setPhone] = useState("");
    const [cepAddress, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [path, setPath] = useState("https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU");
    // const [file, setFile] = useState({ fileName: "" });
    const [loading, setLoading] = useState(false);

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {
        getPartner(partnerId())
    }, []);

    function checkDay(day) {

        if (workPlaces) {

            let day_pos = null, work_places = workPlaces

            if (work_places[workIndex].days.includes(day)) {
                day_pos = work_places[workIndex].days.indexOf(day)
                work_places[workIndex].days.splice(day_pos, 1)
            }
            else
                work_places[workIndex].days.push(day)

            setWorkPlaces(work_places)
            setDays([...work_places[workIndex].days])
        }
    }


    async function getPartner(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/partner/' + id, config)
            .then(response => {
                setWorkPlaces([{
                    partner_id: response.data.id,
                    name_address: response.data.name + ' - ' + response.data.address,
                    days: []
                }])
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function getDoctor(id, edit, pending) {

        setDoctorId(id)

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/doctor/' + id, config)
            .then(response => {
                setName(response.data.name || "")
                setSpecialities(response.data.specialities.join(", ") || "")
                setCpf(response.data.cpf || "")
                setCrm(response.data.crm || "")
                setPhone(response.data.phone || "");
                setState(response.data.state || "");
                setAddress(response.data.address || "");
                setCep(response.data.cep || "");
                setCity(response.data.city || "");
                setCountry(response.data.country || "");
                setEmail(response.data.email || "");
                setGender(response.data.gender || null)
                setWork(response.data.clinics || [])
                setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
                setLoading(false)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    function setWork(clinics) {

        let has_clinic = false, clinic_index = 0

        clinics.forEach((clinic, index) => {

            if (clinic.id === parseInt(partnerId())) {
                has_clinic = true
                clinic_index = index
                setWorkIndex(index)
            }

        });

        if (has_clinic) {
            setWorkPlaces([])
            setDays(clinics[clinic_index].days || [])
        }
        else {
            setDays([])
            setWorkPlaces(prevState => [{ ...prevState[0], days: [] }])
        }

        clinics.forEach((clinic) => {

            setWorkPlaces(prevState => prevState.concat({
                partner_id: clinic.id,
                name_address: clinic.name + ' - ' + clinic.address,
                days: clinic.days,
                is_accepted: clinic.is_accepted
            }))

        });

    }

    async function vinculateDoctor(id, accept) {

        const data = {
            partner_id: id,
            doctor_id: doctorId,
            is_accepted: accept,
            days
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/clinicDoctor', data, config)
            .then(response => {
                console.log("Médico vinculado com sucesso")
                if (accept === true)
                    notify("success", "Médico vinculado com sucesso!")
                else if (accept == null)
                    notify("success", "Aguarde a aprovação do médico!")
                else
                    notify("success", "Médico recusado com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                notify("warning", "Ocorreu um erro! Por favor, tente mais tarde.")
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    async function editDoctor(id) {

        const data = { days }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/clinicDoctor/' + id, data, config)
            .then(response => {
                console.log("Médico atualizado com sucesso")
                notify("success", "Dias de trabalho atualizados com sucesso!")
                window.location.reload();
            })
            .catch(err => {
                notify("warning", "Ocorreu um erro! Por favor, tente mais tarde.")
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })

    }

    function submit() {

        if (doctorId) {
            if (isEditable)
                editDoctor(partDocId)
            else if (isPending)
                vinculateDoctor(partnerId(), true)
            else
                vinculateDoctor(partnerId(), null)
        }

    }

    function callDoctor(id, edit, pending) {
        setIsEditable(edit)
        setIsPending(pending)
        setLoading(true);
        getDoctor(id, edit, pending)
    }

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: productType === "Pendentes"
                                        })}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={() => setProductType("Pendentes")}
                                    >
                                        <input
                                            defaultChecked
                                            className="d-none"
                                            name="options"
                                            type="radio"
                                        />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Pendentes
                          </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bag-16" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: productType === "Todos"
                                        })}
                                        onClick={() => setProductType("Todos")}
                                    >
                                        <input
                                            className="d-none"
                                            name="options"
                                            type="radio"
                                        />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Todos
                          </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    {productType === "Pendentes" ? <DocPendTable pendingDocs={true} select={(id) => callDoctor(id, false, true)} /> : <DocAllTable allDocs={true} select={(id) => callDoctor(id, false, false)} />}
                </Card>
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h5 className="title">Adicione um médico à sua equipe</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    {
                                        loading ?
                                            <Row className="justify-content-md-center">
                                                <Col md="auto">
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className="pr-md-1" md="4">
                                                    <FormGroup>
                                                        <label>Nome do médico</label>
                                                        <Input
                                                            disabled={true}
                                                            placeholder="Nome completo"
                                                            type="text"
                                                            value={name}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="px-md-1" md="4">
                                                    <FormGroup>
                                                        <label>CPF</label>
                                                        <Input
                                                            disabled={true}
                                                            placeholder="Cadastro de Pessoa Física"
                                                            type="text"
                                                            value={cpf}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pl-md-1" md="4">
                                                    <FormGroup>
                                                        <label>CRM</label>
                                                        <Input
                                                            disabled={true}
                                                            placeholder="Conselho Regional de Medicina"
                                                            type="text"
                                                            value={crm}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                    }
                                    <Row>
                                        <Col className="pr-md-1" md="8">
                                            <FormGroup>
                                                <label>Especialidades</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="Especialidades do médico"
                                                    type="text"
                                                    value={specialities}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>E-mail</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="exemplo@hotmail.com"
                                                    type="text"
                                                    value={email}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label>Telefone</label>
                                                <Input
                                                    placeholder="Telefone do médico"
                                                    type="text"
                                                    value={phone}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="6">
                                            <FormGroup>
                                                <label>CEP</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="CEP do médico"
                                                    type="text"
                                                    value={cepAddress}
                                                />
                                            </FormGroup>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Logradouro</label>
                                                <Input
                                                    disabled={true}
                                                    placeholder="Endereço do médico"
                                                    type="text"
                                                    value={address}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>País</label>
                                                <Input
                                                    placeholder="País do médico"
                                                    type="text"
                                                    value={country}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup>
                                                <label>Estado</label>
                                                <Input
                                                    placeholder="Estado do médico"
                                                    type="text"
                                                    value={state}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Cidade</label>
                                                <Input
                                                    placeholder="Cidade do médico"
                                                    type="text"
                                                    value={city}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label>Dias de trabalho</label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Dom"}
                                                        checked={days.includes("Dom")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Domingo
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Seg"}
                                                        checked={days.includes("Seg")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Segunda
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Ter"}
                                                        checked={days.includes("Ter")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Terça
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Qua"}
                                                        checked={days.includes("Qua")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Quarta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Qui"}
                                                        checked={days.includes("Qui")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Quinta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Sex"}
                                                        checked={days.includes("Sex")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Sexta
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        value={"Sab"}
                                                        checked={days.includes("Sab")}
                                                        onChange={(val) => checkDay(val.target.value)}
                                                    />
                                                    Sábado
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <label>Sexo</label>
                                            <div className="ml-3">
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"
                                                        disabled={true}
                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios11"
                                                                value="M"
                                                                checked={gender === 'M'}
                                                            />
                                                            Masculino
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        check
                                                        inline
                                                        className="form-check-radio"
                                                        disabled={true}
                                                    >
                                                        <Label className="form-check-label">
                                                            <Input
                                                                type="radio"
                                                                name="exampleRadios1"
                                                                id="exampleRadios12"
                                                                value="F"
                                                                checked={gender === 'F'}
                                                            />
                                                            Feminino
                                                            <span className="form-check-sign"></span>
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="card-plain">
                                                <CardBody>
                                                    <WorkPlacesTable workPlaces={workPlaces} days={days} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    className="btn-fill"
                                    color="info"
                                    type="submit"
                                    onClick={submit}
                                >
                                    {isPending ? "Aceitar" : "Salvar"}
                                </Button>
                                {
                                    isPending
                                        ?
                                        <Button
                                            className="btn-fill"
                                            color="danger"
                                            type="submit"
                                            onClick={() => vinculateDoctor(partnerId(), false)}
                                        >
                                            Recusar
                                        </Button>
                                        : null
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <CardText />
                                <div className="author">
                                    <a href="#pablo" onClick={e => e.preventDefault()} className="d-flex justify-content-center">
                                        <div className="card-user">
                                            <img
                                                className="img-partner"
                                                alt="..."
                                                src={path}
                                            />
                                        </div>
                                    </a>

                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className="button-container">
                                    <h5 className="description">{name}</h5>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <DocPendTable allDocs={false} edit={(id, part_doc_id) => {
                    callDoctor(id, true, false)
                    setPartDocId(part_doc_id)
                }} />
            </div>
        </>
    );

}

export default Doctors;
