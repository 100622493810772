/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard/Dashboard.jsx";
import Icons from "./views/Icons.jsx";
import Map from "./views/Map.jsx";
import Notifications from "./views/Notifications.jsx";
import TableList from "./views/TableList.jsx";
import Typography from "./views/Typography.jsx";
import UserProfile from "./views/UserProfile.jsx";
import Register from "./views/Register/Register.jsx";
import Doctors from "./views/Doctors/Doctors.jsx";
import MedicinesAdmin from "./views/Medicines/MedicinesAdmin.jsx";
import MedicinesPartner from "./views/Medicines/MedicinesPartner.jsx";
import AllProductsPartner from './views/Products/AllProductsPartner.jsx';
import VaccinesPartner from "./views/Vaccines/VaccinesPartner.jsx";
import VaccinesAdmin from "./views/Vaccines/VaccinesAdmin.jsx";
import UserProfileAdmin from "./views/UserProfileAdmin.jsx";
import UserProfileDoctor from "./views/UserProfileDoctor.jsx";
import DoctorPacients from "./views/Pacients/DoctorPacients.jsx";
import WorkPlace from "./views/WorkPlaces/WorkPlace.jsx";

import { userType } from "./utils";

var routes = [
  //Rotas do médico
  {
    invisible: userType() !== 'Doctor',
    path: "/user-profile",
    name: "Perfil",
    icon: "tim-icons icon-single-02",
    component: UserProfileDoctor,
    layout: "/doctor"
  },
  {
    invisible: userType() !== 'Doctor',
    path: "/work",
    name: "Locais de Trabalho",
    icon: "tim-icons icon-square-pin",
    component: WorkPlace,
    layout: "/doctor"
  },
  {
    invisible: userType() !== 'Doctor',
    path: "/pacients",
    name: "Pacientes",
    icon: "tim-icons icon-badge",
    component: DoctorPacients,
    layout: "/doctor"
  },
  //Rotas do parceiro
  {
    invisible: userType() !== 'Partner',
    path: "/user-profile",
    name: "Perfil",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/partner"
  },
  {
    invisible: userType() !== 'Partner',
    path: "/doctors",
    name: "Médicos",
    icon: "tim-icons icon-badge",
    component: Doctors,
    layout: "/partner"
  },
  {
    invisible: userType() !== 'Partner',
    path: "/products",
    name: "Produtos",
    icon: "tim-icons icon-cart",
    component: AllProductsPartner,
    layout: "/partner"
  },
  {
    invisible: userType() !== 'Partner',
    path: "/medicines",
    name: "Medicamentos",
    icon: "tim-icons icon-atom",
    component: MedicinesPartner,
    layout: "/partner"
  },

  {
    invisible: userType() !== 'Partner',
    path: "/vaccines",
    name: "Vacinas",
    icon: "tim-icons icon-sound-wave",
    component: VaccinesPartner,
    layout: "/partner"
  },

  //Rotas do administrador
  {
    invisible: userType() !== 'Admin',
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    invisible: userType() !== 'Admin',
    path: "/user-profile",
    name: "Perfil",
    icon: "tim-icons icon-single-02",
    component: UserProfileAdmin,
    layout: "/admin"
  },
  {
    invisible: userType() !== 'Admin',
    path: "/register",
    name: "Cadastro",
    icon: "tim-icons icon-badge",
    component: Register,
    layout: "/admin"
  },
  {
    invisible: userType() !== 'Admin',
    path: "/medicines",
    name: "Medicamentos",
    icon: "tim-icons icon-atom",
    component: MedicinesAdmin,
    layout: "/admin"
  },
  {
    invisible: userType() !== 'Admin',
    path: "/vaccines",
    name: "Vacinas",
    icon: "tim-icons icon-sound-wave",
    component: VaccinesAdmin,
    layout: "/admin"
  },
  //Rotas do template
  {
    invisible: true,
    path: "/tables",
    name: "Table List",
    icon: "tim-icons icon-puzzle-10",
    component: TableList,
    layout: "/admin"
  },
  {
    invisible: true,
    path: "/icons",
    name: "Icons",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: "/admin"
  },
  {
    invisible: true,
    path: "/map",
    name: "Map",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin"
  },
  {
    invisible: true,
    path: "/notifications",
    name: "Notifications",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin"
  },
  {
    invisible: true,
    path: "/typography",
    name: "Typography",
    icon: "tim-icons icon-align-center",
    component: Typography,
    layout: "/admin"
  },
];
export default routes;
