import React, { useState, forwardRef } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
} from 'reactstrap';
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';

import { token } from '../../utils'
import api from '../../services/api'

function PacientsTable(props) {

    const [size, setPageSize] = useState(5);

    const tableIcons = {
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }


    function selectPacient(id) {
        props.select(id)
    }

    const getPacients = async (query) => {
        return new Promise(resolve => {
            const config = {
                headers: { Authorization: `Bearer ${token()}` }
            }
            setPageSize(query.pageSize)
            if(query.search){
                api.get(`/searchPacients?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
                .then(res => {
                    resolve({
                        data: res.data.data,
                        page: res.data.page - 1,
                        totalCount: res.data.total
                    })
                })
                .catch(err => {
                    console.log('Erro: ' + JSON.stringify(err.response.data))
                })
            }
            else{
                api.get(`/showPacients?page=${query.page + 1}&limit${query.limit}`, config)
            .then(response => {
                // console.log(response.data)
                return resolve({
                    data: response.data.data,
                    page: response.data.page - 1,
                    totalCount: response.data.total
                })
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })
            }
        })



    }


    return (
        <Card className="card-plain">
            <CardHeader>
                <CardTitle tag="h4">Lista Dos Pacientes Que O Adicionaram</CardTitle>
                <CardSubtitle className="category">Busque e selecione um paciente que o adicionou</CardSubtitle>
            </CardHeader>
            <CardBody>
                <MaterialTable
                    title="Lista de Todos os Medicamentos"
                    style={{ backgroundColor: "#f5f6fa" }}
                    columns={[
                        {
                            title: '', field: 'icon', render: (rowData) => (
                                <>
                                    <Button
                                color="link"
                                type="button"
                                onClick={() => selectPacient(rowData.id)}
                            >
                                <i className="tim-icons icon-single-02" />
                            </Button>
                                </>
                            )
                        },
                        { title: 'Nome', field: 'name', width: 20, align: 'center' },
                        { title: 'Email', field: 'email', with: 10, align: 'center' },
                        { title: 'Sexo', field: 'gender', with: 15, align: 'center' },
                        { title: 'Telefone', field: 'phone', with: 15, align: 'center' },
                    ]}
                    options={{
                        showTitle: false,
                        header: true,
                        headerStyle: {
                            color: '#000',
                            fontWeight: 'bold',
                            backgroundColor: '#f5f6fa',
                            fontFamily: 'Poppins',
                        },
                        padding: 'default',
                        initialPage: 0,
                        pageSizeOptions: [1, 2, 5, 10, 15],
                        pageSize: size,
                        search: true,
                        searchFieldAlignment: 'left',
                        searchFieldStyle: {
                            color: '#000',
                            backgroundColor: (30, 30, 47, 0.3),
                        },
                    }}
                    localization={{

                        body: {
                            emptyDataSourceMessage: 'Sem pacientes para mostrar',
                        },
                        pagination: {
                            labelRowsSelect: 'linhas',
                            labelDisplayedRows: '{count} de {from}-{to}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            searchPlaceholder: 'Procurar'
                        },
                    }}
                    data={query => getPacients(query)}
                    icons={tableIcons}
                />
            </CardBody>
        </Card>
    )

}

export default PacientsTable;