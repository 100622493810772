import React, { useState, useEffect} from "react";

import {
  Alert
} from "reactstrap";



function NotificationAlert(props) {
  const [visible, setVisible] = useState(props.visible);
  const [situation, setSituation] = useState("Sucesso!");
  const [message, setMessage] = useState("");


  useEffect(() => {
    if (props.color === "success") {
      setSituation("Sucesso!")
      setMessage("Operação concluída com sucesso! " + props.message)
    }
  
    else if (props.color === "warning") {
      setSituation("Erro!")
      setMessage("Não foi possível concluir a operação desejada! " + props.message)
    }
  
    else if (props.color === "info") {
      setSituation("Warning!")
      setMessage(props.message)
    }
  },[props.color])   

  
  function onDimiss() {
    setVisible(false)

    //props.visible = false
  }

  return (
    <>
      <div className="content">
        <Alert
          //colors: success, warning, info, dark, primary, danger
          color={props.color}

          isOpen={visible}
          toggle={onDimiss}
        >
          <span>
            <b> {situation} </b> {message}
            <code className="highlighter-rouge"></code>
          </span>
        </Alert>
      </div>

    </>

  );

}

export default NotificationAlert;