/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import FileInput from "../../../components/FileInput"
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Button,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import { token } from '../../../utils'
import api from '../../../services/api'

import cep from 'cep-promise';
import { mask } from 'remask'

function AdmForm(props) {
    async function storePicture(id) {

        let data = new FormData();
        data.append('profile_pic', file, file.fileName);
        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/sessions/' + id + "/image", data, config)
            .then(response => {
                console.log("Foto atualizada com sucesso")
                window.location.reload();
            })
            .catch(error => {
                console.log('Erro: ' + JSON.stringify(error.response.data))
                notify("warning", "Erro ao atualizar foto! Tente mais tarde.")
                window.location.reload();
            })

    }
    //Dados do formulário
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPass, setConfPass] = useState("");
    const [cepAddress, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    // const [path, setPath] = useState("https://i.ytimg.com/vi/L1tx-wAI6Nw/maxresdefault.jpg");
    const [file, setFile] = useState({ fileName: "" });

    //Outras variáveis
    const notificationAlert = useRef(null);
    const [edit, setEdit] = useState(false);
    const [errorPassword, setErrorPassword] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorConf, setErrorConf] = useState("");
    // const [errorCep, setErrorCep] = useState("");
    const [loading, setLoading] = useState(false)

    const handleCepChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let val = value
        setCep(val);
        if (val.length === 9) {
            cep(value).then(function (endereco) {
                setCity(endereco.city);
                setAddress(endereco.street + ", " + endereco.neighborhood);
                setState(endereco.state);
                setCountry("Brasil")
                // setErrorCep("")
            });
        }

    };

    useEffect(() => {
        props.name(name)
    }, [name]);

    const handleNameChange = (e) => {

        e.preventDefault();
        let value = e.target.value

        setName(value);
        if (value.length === 0) {
            // input vazio
            setErrorName("has-danger")
        }
        else
            setErrorName("")
    }

    const handleEmailChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        setEmail(value);
        if (value.length === 0) {
            // input vazio
            setErrorEmail("has-danger")
        }
        else
            setErrorEmail("")
    }

    const handlePasswordChange = (e) => {
        let value = e.target.value;
        setPassword(value);
    }

    function onBlurPasswordHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorPassword("has-danger")
        }
    }

    const handleConfPassChange = (e) => {
        let value = e.target.value;
        setConfPass(value);
    }

    function onBlurConfHandle() {
        if (((password.length !== 0) && (confPass.length !== 0))) {
            validPassword();
        }
        else if (password.length === 0) {
            setErrorConf("has-danger")
        }
    }

    useEffect(() => {
        if (props.admId) {
            setLoading(true)
            getAdm(props.admId)
        }
    }, [props.admId]);

    async function getAdm(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/user/' + id, config)
            .then(response => {

                setEdit(true)
                setPassword("")
                setConfPass("")
                setName(response.data.name || "")
                setEmail(response.data.email || "")
                setCep(response.data.cep || "")
                setAddress(response.data.address || "")
                setCountry(response.data.country || "")
                setState(response.data.state || "")
                setCity(response.data.city || "")
                setPhone(response.data.phone || "")
                // console.log(response.data)
                props.path(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar dados do usuário selecionado! Tente mais tarde.")
            })
        setLoading(false)
    }

    async function storeAdm() {

        const data = {
            name,
            email,
            password,
            type_connection: "local",
            phone,
            address,
            state,
            city,
            country,
            cep: cepAddress,
            user_type: "Admin"
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/register', data, config)
            .then(response => {
                console.log("Cadastrado com sucesso")
                if (file.fileName !== "")
                    storePicture(response.data.id)

                else
                    window.location.reload();
                notify("success", "Usuário cadastrado com sucesso!")
            })
            .catch(err => {
                notify("warning", "Erro! Não foi possível atualizar os dados! Tente mais tarde.");
                console.log('Erro: ' + JSON.stringify(err.response.data))
            })
    }

    async function editAdm(id) {

        const data = {
            name,
            phone,
            address,
            state,
            city,
            country,
            cep: cepAddress
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/user/' + id, data, config)
            .then(response => {
                console.log("Usuário editado com sucesso")
                notify("success", "Usuário atualizado com sucesso!")
                if (file.fileName !== "")
                    storePicture(id)
                else
                    window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro! Não foi possível criar o usuário! Tente mais tarde.");
            })
        notify("tc");

    }


    function submit() {
        if (!error()) {
            setLoading(true)
            if (edit)
                editAdm(props.admId)
            else
                storeAdm()
            setLoading(false)
        }
    }

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function error() {
        if (name.length !== 0 & email.length !== 0) {
            if (!edit & password.length !== 0
                & confPass.length !== 0) {
                if (validPassword())
                    return false;
                return true;
            }
            return false;
        }
        else {
            if (name.length === 0) {
                setErrorName("has-danger")
            }
            if (email.length === 0) {
                setErrorEmail("has-danger");
            }
            if (!edit) {
                if (password.length === 0) {
                    setErrorPassword("has-danger")
                }
                else if (confPass.length === 0)
                    setErrorConf("has-danger")
            }
            notify("warning", "Nem todos os campos foram preenchidos!" +
                " Por favor preencha todos os campos obrigatórios para poder atualizar seu cadastro!")
        }
        return true;
    }


    function validPassword() {
        if (password !== confPass) {
            setErrorPassword("has-danger");
            setErrorConf("has-danger");
            notify("warning", "A senha informada não é igual a senha confirmada!")
            return false;
        }
        else {
            setErrorPassword("");
            setErrorConf("");
            return true;
        }
    }


    return (
        <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <CardBody>
                <Form>
                    <Row>
                        <Col className="pr-md-1" md="5">
                            <FormGroup className={errorName}>
                                <label>Nome*</label>
                                <Input
                                    value={name}
                                    placeholder="Digite seu nome"
                                    type="text"
                                    onChange={handleNameChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                            <FormGroup className={errorEmail}>
                                <label htmlFor="exampleInputEmail1">
                                    E-mail*
                                </label>
                                <Input
                                    disabled={edit}
                                    value={email}
                                    placeholder="exemplo@email.com"
                                    type="email"
                                    onChange={handleEmailChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="3">
                            <FormGroup>
                                <label>Foto</label>
                                <FileInput path={(path) => props.path(path)} file={setFile} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        loading ?
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <CircularProgress />
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col className="pr-md-1" md="4">
                                    <FormGroup className={errorPassword}>
                                        <label>Senha*</label>
                                        <Input
                                            disabled={edit}
                                            value={password}
                                            placeholder="Digite sua senha"
                                            type="password"
                                            onChange={handlePasswordChange}
                                            onBlur={onBlurPasswordHandle}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="px-md-1" md="4">
                                    <FormGroup className={errorConf}>
                                        <label>Confirme a senha*</label>
                                        <Input
                                            disabled={edit}
                                            value={confPass}
                                            placeholder="Digite a mesma senha"
                                            type="password"
                                            onChange={handleConfPassChange}
                                            onBlur={onBlurConfHandle}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="pl-md-1" md="4">
                                    <FormGroup >
                                        <label>Telefone</label>
                                        <Input
                                            value={phone}
                                            placeholder="Telefone do administrador"
                                            type="text"
                                            onChange={(txt) => setPhone(mask(txt.target.value, '+99(99)99999-9999'))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col className="pr-md-1" md="8">
                            <FormGroup>
                                <label>Logradouro</label>
                                <Input
                                    value={address}
                                    placeholder="Endereço do administrador"
                                    type="text"
                                    onChange={(txt) => setAddress(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                            <FormGroup>
                                <label>CEP</label>
                                <Input
                                    value={cepAddress}
                                    placeholder="CEP do administrador"
                                    type="text"
                                    onChange={(txt) => setCep(mask(txt.target.value, '99999-999'))}
                                    onBlur={handleCepChange}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="4">
                            <FormGroup>
                                <label>País</label>
                                <Input
                                    value={country}
                                    placeholder="País do administrador"
                                    type="text"
                                    onChange={(txt) => setCountry(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                            <FormGroup>
                                <label>Estado</label>
                                <Input
                                    value={state}
                                    placeholder="Estado do admnistrador"
                                    type="text"
                                    onChange={(txt) => setState(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                            <FormGroup>
                                <label>Cidade</label>
                                <Input
                                    value={city}
                                    placeholder="Cidade do administrador"
                                    type="text"
                                    onChange={(txt) => setCity(txt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Button
                    className="btn-fill"
                    color="info"
                    type="submit"
                    onClick={submit}
                >
                    {edit ? "Salvar" : "Cadastrar"}
                </Button>
            </CardFooter>
        </>
    );
}

export default AdmForm;
