/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import VacDataTable from "./VacDataTable";
import VaccinesTable from "./VaccinesTable";
import VaccinesCreateTable from "./VaccinesCreateTable";

function VaccinesPartner() {

    const [vaccineId, setVaccineId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const isPartner = true;

    return (
        <>
            <div className="content">
                <VacDataTable
                    isPartner={isPartner}
                    select={(id) => {
                        setVaccineId(id)
                        setIsEditable(false)
                    }}
                />
                <VaccinesCreateTable
                    disabled={isPartner}
                    isPartner={isPartner}
                    vaccineId={vaccineId}
                    isEditable={isEditable}
                />
                {
                    isPartner ?
                        <VaccinesTable
                            edit={(id) => {
                                setVaccineId(id)
                                setIsEditable(true)
                            }}
                        />
                        : null
                }

            </div>
        </>
    );

}

export default VaccinesPartner;
