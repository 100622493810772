/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import MedDataTable from "./components/MedDataTable";
import MedCreateTable from "./components/MedCreateTable";

function MedicinesAdmin() {

    const [medId, setMedId] = useState(null);
    const userPartner = false;
    const [edit, setEdit] = useState(false)

    const handleEditMedData = (id) => {
        setMedId(id);
        setEdit(true);
    }

    return (
        <>
            <div className="content">
                <MedDataTable edit={handleEditMedData} isPartner={userPartner} />
                <MedCreateTable edit={edit} disabled={userPartner} isPartner={userPartner} medicineId={medId} />
            </div>
        </>
    );

}

export default MedicinesAdmin;
