import React, { useState,  useRef, forwardRef } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import NotificationAlert from "react-notification-alert";
import MaterialTable from 'material-table';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';

import { token } from '../../../utils'
import api from '../../../services/api'

export default function AdmTable({ edit }) {

  const notificationAlert = useRef(null);
  const [size, setPageSize] = useState(5);

  const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <i {...props} className="tim-icons icon-zoom-split search-icon" />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  }

  function notify(id) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div >
          <div>
            <div className="text-center">
              Deseja deletar o administrador selecionado?
              </div>
            <div className="d-flex justify-content-center">
              <Button
                className="btn-link"
                color="light"
                type="submit"
                onClick={() => {
                  // console.log(false)
                  deleteAdm(id)
                }
                }
              >
                Sim
                </Button>
            </div>
          </div>
        </div>
      ),

      type: "info",
      icon: "tim-icons",
      autoDismiss: false
    };
    notificationAlert.current.notificationAlert(options);
  };

  // useEffect(() => {
  //   getAdmins();
  // }, []);

  const getAdmins = async (query) => {
    return new Promise(resolve => {
      const config = {
        headers: { Authorization: `Bearer ${token()}` }
      }
      setPageSize(query.pageSize);
      if (query.search) {
        api.get(`/searchAdm?query=${query.search}&page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(res => {
            resolve({
              data: res.data.data,
              page: res.data.page - 1,
              totalCount: res.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
      else {
        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        api.get(`/admin?page=${query.page + 1}&limit=${query.pageSize}`, config)
          .then(response => {
            resolve({
              data: response.data.data,
              page: response.data.page - 1,
              totalCount: response.data.total
            })
          })
          .catch(err => {
            console.log('Erro: ' + JSON.stringify(err.response.data))
          })
      }
    })
  }

  async function deleteAdm(id) {

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    await api.delete('/user/' + id, config)
      .then(response => {
        console.log("Administrador excluído com sucesso")
        window.location.reload();
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
      })

  }

  async function activateAdmin(id) {

    const data = { is_deleted: false }

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.put('/user/' + id, data, config)
      .then(response => {
        console.log("Admnistrador ativado com sucesso!")
        window.location.reload();
      })
      .catch(error => {
        console.log("Erro! " + JSON.stringify(error.response.data))
      })

  }


  function editAdm(id) {
    edit(id)
  }


  return (
    <Card className="card-plain">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <CardHeader>
        <CardTitle tag="h4">Lista De Administradores</CardTitle>
        <CardSubtitle className="category">Visualize, edite ou exclua os administradores</CardSubtitle>
      </CardHeader>
      <CardBody>
        <MaterialTable
          title="Lista de Todos os Medicamentos"
          style={{ backgroundColor: "#f5f6fa" }}
          columns={[
            {
              title: '', field: 'icon', render: (rowData) => (
                <>
                  <Button
                    color="link"
                    type="button"
                    onClick={() => editAdm(rowData.id)}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {
                    rowData.is_deleted
                      ?
                      <Button
                        color="link"
                        type="button"
                        onClick={() => activateAdmin(rowData.id)}
                      >
                        <i className="tim-icons icon-check-2" />
                      </Button>
                      :
                      <Button
                        color="link"
                        type="button"
                        onClick={() => notify(rowData.id)}
                      >
                        <i className="tim-icons icon-trash-simple" />
                      </Button>
                  }
                </>
              )
            },
            { title: 'Nome', field: 'name', width: 20, align: 'center' },
            { title: 'Email', field: 'email', with: 10, align: 'center' },
            { title: 'Telefone', field: 'phone', with: 15, align: 'center' },
            { title: 'Endereço', field: 'address', with: 15, align: 'center' },
            {
              title: 'Ativo', field: 'is_deleted', align: 'center',
              lookup: { 1: 'Não', 0: 'Sim' }
            },
          ]}
          options={{
            showTitle: false,
            header: true,
            headerStyle: {
              color: '#000',
              fontWeight: 'bold',
              backgroundColor: '#f5f6fa',
              fontFamily: 'Poppins',
            },
            padding: 'default',
            initialPage: 0,
            pageSizeOptions: [1, 2, 5, 10, 15],
            pageSize: size,
            search: true,
            searchFieldAlignment: 'left',
            searchFieldStyle: {
              color: '#000',
              backgroundColor: (30, 30, 47, 0.3),
            },
          }}
          localization={{

            body: {
              emptyDataSourceMessage: 'Sem administradores para mostrar',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Procurar'
          },
          }}
          data={query => getAdmins(query)}
          icons={tableIcons}
        />
      </CardBody>
    </Card>
  )

}