/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/Admin/Admin.jsx";
import Login from "./layouts/Login.jsx";
import ForgotPassword from "./layouts/ForgotPassword.jsx";
import ResetPassword from "./views/ResetPassword.jsx";
import DoctorRegister from "./views/DoctorRegister.jsx";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <PublicRoute restricted={false} path="/login" component={Login} />
      <PublicRoute restricted={false} path="/forgotpassword" component={ForgotPassword} />
      <PublicRoute restricted={false} path="/register" component={DoctorRegister} />
      <PublicRoute restricted={false} path="/resetpassword" component={ResetPassword} />
      <PrivateRoute path="/admin" component={AdminLayout} />
      <PrivateRoute path="/partner" component={AdminLayout} />
      <PrivateRoute path="/doctor" component={AdminLayout} />


      <Redirect from="/" to="/login" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
