import React, { useEffect, useState, useRef } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Label,
    Input,
    Row,
    Col,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import CircularProgress from '@material-ui/core/CircularProgress';

import { token } from '../../../utils'
import api from '../../../services/api'
import { mask, unMask } from 'remask'
function MedCreateTable(props) {

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState('');
    const [reference, setReference] = useState("");
    const [errorRef, setErrorRef] = useState('');
    const [active_principal, setActivePrincipal] = useState("");
    const [errorActive, setErrorActive] = useState('')
    const [concentration, setConcentration] = useState("");
    const [errorConc, setErrorConc] = useState("");
    const [type, setType] = useState("");
    const [errorType, setErrorType] = useState("");
    const [owner, setOwner] = useState("");
    const [price, setPrice] = useState("");
    const [errorPrice, setErrorPrice] = useState("");
    const [stock, setStock] = useState("");
    const [errorStock, setErrorStock] = useState("");
    const notificationAlert = useRef(null);
    const [free, setFree] = useState(false);
    const [loading, setLoading] = useState(false)

    function notify(situation, text) {
        var options = {};
        options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),

            type: situation,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function error() {
        if (props.isPartner) {
            if (price.length !== 0 & stock.length !== 0 & name.length !== 0) {
                return false;
            }
            else {
                if (name.length === 0) {
                    notify("warning", "É necessário selecionar um remédio!");
                }
                else {
                    if (price.length === 0) {
                        setErrorPrice("has-danger");
                    }
                    else
                        setErrorPrice("")
                    if (stock.length === 0) {
                        setErrorStock("has-danger");
                    }
                    else
                        setErrorStock("")
                    notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
                }
                return true;
            }
        }
        else {
            if (name.length !== 0 & reference.length !== 0 & active_principal.length !== 0 &
                concentration.length !== 0 & type.length !== 0) {
                return false;
            }
            else {
                if (name.length === 0) {
                    setErrorName("has-danger")
                }
                else
                    setErrorName("")
                if (reference.length === 0)
                    setErrorRef("has-danger")
                else
                    setErrorRef("")
                if (active_principal.length === 0)
                    setErrorActive("has-danger")
                else
                    setErrorActive("")
                if (concentration.length === 0)
                    setErrorConc("has-danger")
                else
                    setErrorConc("has-danger")
                if (type.length === 0)
                    setErrorType("has-danger")
                else
                    setErrorType("")

                notify("warning", "Erro! Nem todos os campos obrigatórios estão preenchidos!");
                return true;
            }
        }
    }

    useEffect(() => {
        if (props.medicineId) {
            setLoading(true)

            if (!props.isEditable)
                getMedicine(props.medicineId)
            else if (props.isEditable)
                getPartnerMed(props.medicineId)

            setLoading(false)
        }
    }, [props.medicineId]);

    async function getMedicine(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/meddata/' + id, config)
            .then(response => {
                setName(response.data.name || "")
                setReference(response.data.reference || "")
                setActivePrincipal(response.data.active_principal || "")
                setConcentration(response.data.concentration || "")
                setType(response.data.type || "")
                setOwner(response.data.owner || "")
                setPrice("")
                setStock("")
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar informações sobre o medicamento! Tente novamente mais tarde.");
            })

    }

    async function getPartnerMed(id) {

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.get('/partnermed/' + id, config)
            .then(response => {
                setName(response.data.name || "")
                setReference(response.data.reference || "")
                setActivePrincipal(response.data.active_principal || "")
                setConcentration(response.data.concentration || "")
                setType(response.data.type || "")
                setOwner(response.data.owner || "")
                setPrice(response.data.price || "")
                setStock(response.data.stock || "")
                if (response.data.price === 0)
                    setFree(true)
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao buscar informações sobre o medicamento! Tente novamente mais tarde.");
            })

    }

    async function editMedicine(id) {
        // console.log(stock)
        const data = {
            stock,
            price
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/partnermed/' + id, data, config)
            .then(response => {
                console.log("Remédio editado com sucesso")
                notify("success", "Remédio editado com sucesso!");
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                notify("warning", "Erro ao editar o remédio! Tente novamente mais tarde");
            })

    }

    async function storeMedicine() {

        const data = {
            stock,
            price,
            meddata_id: props.medicineId
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/partnermed', data, config)
            .then(response => {
                console.log("Remédio cadastrado com sucesso")
                notify("success", "Remédio cadastrado com sucesso!");
                window.location.reload();
            })
            .catch(err => {
                console.log('Erro: ' + JSON.stringify(err.response.data))
                if (err.response.status === 500) {
                    notify("warning", "Erro! Não é possível adicionar o remédio ao seu estoque pois você já possui!")
                }
            })

    }

    async function storeMedData() {

        const data = {
            reference,
            active_principal,
            name,
            owner,
            type,
            concentration
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.post('/meddata', data, config)
            .then(response => {
                console.log("Medicamento cadastrado com sucesso")
                notify("success", "Medicamento cadastrado com sucesso!");
                window.location.reload()
            })
            .catch(error => {
                console.log("Erro! " + JSON.stringify(error.response.data))
                notify("warning", "Erro ao cadastrar medicamento! Tente novamente mais tarde.");
            })

    }

    async function editMedData(id) {
        const data = {
            reference,
            active_principal,
            name,
            owner,
            type,
            concentration
        }

        const config = {
            headers: { Authorization: `Bearer ${token()}` }
        }

        //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
        await api.put('/meddata/' + id, data, config)
            .then(response => {
                console.log("Medicamento editado com sucesso!")
                notify("success", "Medicamento editado com sucesso!");
                window.location.reload();
            })
            .catch(error => {
                console.log("Erro! " + JSON.stringify(error.response.data))
                notify("warning", "Erro ao editar medicamento! Tente novamente mais tarde.");
            })

    }



    function submit() {

        if (!error()) {
            setLoading(true)
            if (props.isPartner) {

                if (props.medicineId && !props.isEditable)
                    storeMedicine()
                else if (props.medicineId && props.isEditable)
                    editMedicine(props.medicineId)
            }
            else {

                if (props.edit)
                    editMedData(props.medicineId)
                else
                    storeMedData()
            }
            setLoading(false)
        }
    }


    return (
        <>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <h5 className="title">Adicione um medicamento ao seu estoque</h5>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <p className="category">Informações gerais do medicamento</p>
                                {
                                    loading ?
                                        <Row className="justify-content-md-center">
                                            <Col md="auto">
                                                <CircularProgress />
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup className={errorName}>
                                                    <label>Nome*</label>
                                                    <Input
                                                        value={name}
                                                        onChange={(txt) => {
                                                            setName(txt.target.value)
                                                            if (name.length !== 0)
                                                                setErrorName("")
                                                        }}
                                                        placeholder="Nome do medicamento"
                                                        type="text"
                                                        disabled={props.disabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="px-md-1" md="4">
                                                <FormGroup className={errorRef}>
                                                    <label>Referência*</label>
                                                    <Input
                                                        value={reference}
                                                        onChange={(txt) => {
                                                            setReference(txt.target.value)
                                                            if (reference.length !== 0)
                                                                setErrorRef("")
                                                        }}
                                                        placeholder="Medicamento similar"
                                                        type="text"
                                                        disabled={props.disabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pl-md-1" md="4">
                                                <FormGroup className={errorActive}>
                                                    <label>Princípio ativo*</label>
                                                    <Input
                                                        value={active_principal}
                                                        onChange={(txt) => {
                                                            setActivePrincipal(txt.target.value)
                                                            if (active_principal.length !== 0)
                                                                setErrorActive("")
                                                        }}
                                                        placeholder="Substância principal"
                                                        type="text"
                                                        disabled={props.disabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                }
                                <Row>
                                    <Col className="pr-md-1" md="6">
                                        <FormGroup className={errorConc}>
                                            <label>Concentração*</label>
                                            <Input
                                                value={concentration}
                                                onChange={(txt) => {
                                                    setConcentration(txt.target.value)
                                                    if (concentration.length !== 0)
                                                        setErrorConc("")
                                                }}
                                                placeholder="15 MG, 20 ML, ..."
                                                type="text"
                                                disabled={props.disabled}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="pl-md-1" md="6">
                                        <FormGroup className={errorType}>
                                            <label>Tipo*</label>
                                            <Input
                                                value={type}
                                                onChange={(txt) => {
                                                    setType(txt.target.value)
                                                    if (type.length !== 0)
                                                        setErrorType("")
                                                }}
                                                placeholder="Comprimido, injetável, ..."
                                                type="text"
                                                disabled={props.disabled}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <label>Fabricante</label>
                                            <Input
                                                value={owner}
                                                onChange={(txt) => setOwner(txt.target.value)}
                                                placeholder="Nome do fabricante do medicamento"
                                                type="text"
                                                disabled={props.disabled}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    props.isPartner ?
                                        <>
                                            <p className="category">Informações específicas do medicamento no estabelecimento</p>
                                            <Row>
                                                <Col className="pr-md-1" md="2">
                                                    <label>Tipo de Produto</label>
                                                    <div className="ml-2">
                                                        <FormGroup
                                                            check
                                                            inline
                                                        > <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    // name="exampleRadios1"
                                                                    // id="exampleRadios11"
                                                                    value="gratis"
                                                                    checked={free}
                                                                    onChange={() => {
                                                                        setFree(!free)
                                                                        setPrice(0.00)
                                                                    }}
                                                                />
                                                Amostra Grátis
                                                <span className="form-check-sign"></span>
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <Col className="pr-md-1" md="5">
                                                    <FormGroup className={errorPrice}>
                                                        <label>Preço*</label>
                                                        <Input
                                                            value={price}
                                                            placeholder="R$ 0.00"
                                                            type="text"
                                                            disabled={free}
                                                            onChange={(txt) => {
                                                                setPrice(mask(unMask(txt.target.value), ['9', '99', '9.99', '99.99', '999.99', '9999.99', '99999.99', '999999.99', '9999999.99', '99999999.99']))
                                                                if (price.length !== 0 & !free)
                                                                    setErrorPrice("")
                                                            }}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pl-md-1" md="5">
                                                    <FormGroup className={errorStock}>
                                                        <label>Estoque*</label>
                                                        <Input
                                                            value={stock}
                                                            onChange={(txt) => {
                                                                setStock(txt.target.value)
                                                                if (stock.length !== 0)
                                                                    setErrorStock("");
                                                            }}
                                                            placeholder="Quantidade do medicamento no estoque"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button
                                className="btn-fill"
                                color="info"
                                type="submit"
                                onClick={submit}
                            >
                                Salvar
                        </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    );

}

export default MedCreateTable;