/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import FileInput from "../components/FileInput.jsx";
import NotificationAlert from "react-notification-alert";
import { token, userId } from '../utils'

import api from '../services/api';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import CircularProgress from '@material-ui/core/CircularProgress';

import { mask, unMask } from 'remask'
import cep from 'cep-promise';

function UserProfileAdmin() {
  async function storePicture(id) {

    let data = new FormData();
    data.append('profile_pic', file, file.fileName);
    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.post('/sessions/' + id + "/image", data, config)
      .then(response => {
        console.log("Foto atualizada com sucesso")
        window.location.reload();
      })
      .catch(error => {
        console.log('Erro: ' + JSON.stringify(error.response.data))
        window.location.reload();
      })

  }
  const notificationAlert = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cepAddress, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCep, setErrorCep] = useState("");
  const [path, setPath] = useState("https://i.ytimg.com/vi/L1tx-wAI6Nw/maxresdefault.jpg");
  const [file, setFile] = useState({ fileName: "" });
  const [loading, setLoading] = useState(true);


  const handleCepChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let val = value
    setCep(val);
    if (val.length === 9) {
      cep(value).then(function (endereco) {
        setCity(endereco.city);
        setAddress(endereco.street + ", " + endereco.neighborhood);
        setState(endereco.state);
        setErrorCep("")
      });
    }
    else if (value.length > 10) {
      setErrorCep("has-danger");
    }

  };

  const handleNameChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setName(value);
    if (value.length === 0) {
      // input vazio
      setErrorName("has-danger")
    }
    else
      setErrorName("")
  }

  const handleEmailChange = (e) => {
    e.preventDefault();
    // console.log("entrou email")
    let value = e.target.value;
    setEmail(value);
    if (value.length === 0) {
      // input vazio
      setErrorEmail("has-danger")
    }
    else
      setErrorEmail("")
  }

  const handlePhoneChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setPhone(value);
  }

  const handleAddressChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setAddress(value);
  }

  const handleCountryChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setCountry(value);
  }

  const handleStateChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setState(value);
  }

  const handleCityChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setCity(value);
  }

  useEffect(() => {
    getAdmin();
  }, []);

  async function getAdmin() {
    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.get('/admin/' + userId(), config)
      .then(response => {
        setName(response.data.name || "");
        setPhone(response.data.phone || "");
        setState(response.data.state || "");
        setAddress(response.data.address || "");
        setCep(response.data.cep || "");
        setEmail(response.data.email || "");
        setCity(response.data.city || "");
        setCountry(response.data.country || "");
        setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
        setLoading(false)
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
        notify("warning", "Erro ao buscar informações do perfil! Tente novamente mais tarde.");
      })

  }

  async function editAdmin(id) {
    const data = {
      name,
      email,
      phone,
      address,
      state,
      city,
      country,
      cep: cepAddress
    }
    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }


    await api.put('/admin/' + id, data, config)
      .then(response => {
        console.log("Admin editado com sucesso")
        notify("success", "Usuário atualizado com sucesso com sucesso!")
        if (file.fileName !== "")
          storePicture(userId())
        else
          window.location.reload();
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
        notify("warning", "Erro! Não foi possível atualizar os dados! Tente mais tarde.");
      })
  }

  function onSubmit() {
    if (!error()) {
      setLoading(true)
      editAdmin(userId());
      setLoading(false)
    }
  }

  function error() {
    if (name.length !== 0 & email.length !== 0) {
      return false;
    }
    else {
      if (name.length === 0) {
        setErrorName("has-danger")
      }
      if (email.length === 0) {
        setErrorEmail("has-danger");
      }
      notify("warning", "Nem todos os campos foram preenchidos!" +
        " Por favor preencha todos os campos obrigatórios para poder atualizar seu cadastro!")
    }
    return true;
  }

  function notify(situation, text) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),

      type: situation,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlert.current.notificationAlert(options);
  };



  return (
    <>

      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edite seu perfil</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup className={errorName}>
                        <label>Nome*</label>
                        <Input
                          placeholder="Digite seu nome"
                          type="text"
                          value={name}
                          onChange={handleNameChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup className={errorEmail}>
                        <label htmlFor="exampleInputEmail1">
                          E-mail*
                                </label>
                        <Input
                          placeholder="exemplo@email.com"
                          type="email"
                          disabled={true}
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <label>Foto</label>
                        <FileInput path={setPath} file={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    loading ?
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <CircularProgress />
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label>Telefone</label>
                            <Input
                              placeholder="Número de telefone"
                              type="text"
                              value={phone}
                              onChange={(txt) => setPhone(mask(unMask(txt.target.value), ['+99(99)9999-9999', '+99(99)99999-9999']))}
                              onBlur={handlePhoneChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <FormGroup className={errorCep}>
                            <label>CEP</label>
                            <Input
                              placeholder="CEP"
                              type="text"
                              value={cepAddress}
                              onChange={(txt) => setCep(mask(txt.target.value, '99999-999'))}
                              onBlur={handleCepChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                  }
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Logradouro</label>
                        <Input
                          placeholder="Avenida, Bairo, Rua, Número..."
                          type="text"
                          value={address}
                          onChange={handleAddressChange}
                        />
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>País</label>
                        <Input
                          placeholder="País"
                          type="text"
                          value={country}
                          onChange={handleCountryChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Estado</label>
                        <Input
                          placeholder="Estado"
                          type="text"
                          value={state}
                          onChange={handleStateChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Cidade</label>
                        <Input
                          placeholder="Cidade"
                          type="text"
                          value={city}
                          onChange={handleCityChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                  Salvar
                  </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">

                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img-partner"
                      src={path}
                    />

                  </a>
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <h5 className="description">{name}</h5>
                </div>
              </CardFooter>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );

}

export default UserProfileAdmin;
