import styled from 'styled-components'

export const Title = styled.span`
    font-weight: 300;
    font-size: 23px;
    font-family: 'Poppins', sans-serif;
    color: #3358F4;
`

export const CadastrosNum = styled.div`
    font-weight: 550;
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    color: rgba(0,0,0,0.55);

    text-align: center;
`

export const SubTitle = styled.div`
    font-weight: 300;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
`