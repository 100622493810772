/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    CardText,
} from "reactstrap";



function PartnerPic(props) {

    return (
        <>
            <Card className="card-user">
                <CardBody>
                    <CardText />
                    <img
                        alt="..."
                        className="img-partner"
                        src={props.path}
                    />
                    
                </CardBody>
                <CardFooter>
                    <div className="button-container">
                        <h5 className="description">{props.name}</h5>
                    </div>
                </CardFooter>
            </Card>
        </>
    );

}

export default PartnerPic;
