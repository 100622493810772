/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  Button,
  ButtonGroup,
  Row,
  Col,
} from "reactstrap";




import DashboardPacients from './DashboardPacients'
import DashboardRanking from './DashboardRanking'

// core components
// import {
//   chartExample1,
//   chartExample2,
//   chartExample3,
//   chartExample4
// } from "variables/charts.jsx";


export default function Dashboard(props) {

  const [boardType, setBoardType] = useState("Pacientes")

  return (
    <>
      <div className="content">
        <Row style={{marginBottom: '20px'}}>
          <Col>
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                tag="label"
                className={classNames("btn-simple", {
                  active: boardType === "Pacientes"
                })}
                color="info"
                id="0"
                size="sm"
                onClick={() => setBoardType("Pacientes")}
              >
                <input
                  defaultChecked
                  className="d-none"
                  name="options"
                  type="radio"
                />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Pacientes
                          </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-bag-16" />
                </span>
              </Button>
              <Button
                color="info"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: boardType === "Ranking"
                })}
                onClick={() => setBoardType("Ranking")}
              >
                <input
                  className="d-none"
                  name="options"
                  type="radio"
                />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Ranking
                          </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-single-02" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        { boardType === "Pacientes" ? <DashboardPacients/> : <DashboardRanking/>}
      </div>
    </>
  )
}


