/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import FileInput from "../components/FileInput.jsx"
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from "reactstrap";

import cep from 'cep-promise';
import FormData from 'form-data'
import { mask } from 'remask'
import { token, partnerId, userId } from '../utils'
import api from '../services/api';

import CircularProgress from '@material-ui/core/CircularProgress';

function UserProfile() {

  // const [erro, setErro] = useState(false);
  // const [message, setMessage] = useState("Dados atualizados com sucesso!");
  // const [type, setType] = useState("success");
  const notificationAlert = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [phone, setPhone] = useState("");
  const [cepAddress, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(0);
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCep, setErrorCep] = useState("");
  const [errorLat, setErrorLat] = useState("");
  const [errorLng, setErrorLng] = useState("");
  const [priority, setPriority] = useState(false);
  const [errorCnpj, setErrorCnpj] = useState('');
  const [loading, setLoading] = useState(true);
  // const [blur, setBlur] = useState("form-group alignright blur")

  const [path, setPath] = useState("https://i.ytimg.com/vi/L1tx-wAI6Nw/maxresdefault.jpg");
  const [file, setFile] = useState({ fileName: "" });


  const handleCepChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let val = value
    /*if (val.length === 10) {
      let valu = value.split("-");
      val = valu[0].split(".")
      val = val[0] + val[1] + valu[1]
      console.log(val)
    }*/
    setCep(val);
    if (val.length === 9) {
      cep(value).then(function (endereco) {
        setCity(endereco.city);
        setAddress(endereco.street + ", " + endereco.neighborhood);
        setState(endereco.state);
        setErrorCep("");
      });
    }
    else if (value.length > 10) {
      // setErro(true)
      // setType("warning")
      // setMessage("CEP inválido! Por favor tente novamente!!!")
      setErrorCep("has-danger");
    }

  };

  const handleCnpjChange = (txt) => {

    setCnpj(mask(txt.target.value, '99.999/9999-99'))
    let value = txt.target.value;
    if (value.length !== 0) {
      setErrorCnpj("");
    }
  }

  const handleNameChange = (e) => {
    e.preventDefault();
    let value = e.target.value
    setName(value);
    if (value.length === 0) {
      // input vazio
      // setErro(true)
      // setType("warning")
      // setMessage("Nem todos os campos foram preenchidos!" +
        // " Por favor preencha todos os campos em branco para poder atualizar seu cadastro!")
      setErrorName("has-danger");
    }
    else
      setErrorName("")
  }

  const handleEmailChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    // console.log(value)
    setEmail(value);
    if (value.length === 0) {
      // input vazio
      // setErro(true)
      // setType("warning")
      // setMessage("Nem todos os campos foram preenchidos!" +
      //   " Por favor preencha todos os campos em branco para poder atualizar seu cadastro!")
      setErrorEmail("has-danger");
    }
    else
      setErrorEmail("")
  }

  const handleLatChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setLat(value);
    if (value.length === 0) {
      // input vazio
      // setErro(true)
      // setType("warning")
      // setMessage("Nem todos os campos foram preenchidos!" +
      //   " Por favor preencha todos os campos em obrigatórios para poder atualizar seu cadastro!")
      setErrorLat("has-danger")
    }
    else
      setErrorLat("")
  }

  const handleLongChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setLong(value);
    if (value.length === 0) {
      // input vazio
      // setErro(true)
      // setType("warning")
      // setMessage("Nem todos os campos foram preenchidos!" +
      //   " Por favor preencha todos os campos em obrigatórios para poder atualizar seu cadastro!")
      setErrorLng("has-danger")
    }
    else
      setErrorLng("")
  }

  const handleCategoryChange = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
  }

  useEffect(() => {
    getPartner();
  }, [])

  async function getPartner() {
    // console.log(partnerId())
    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    await api.get('/partner/' + partnerId(), config)
      .then(response => {
        setName(response.data.name || "");
        setPhone(response.data.phone || "");
        setState(response.data.state || "");
        setAddress(response.data.address || "");
        setCep(response.data.cep || "");
        setEmail(response.data.email || "");
        setCity(response.data.city || "");
        setCountry(response.data.country || "");
        setWhatsapp(response.data.whatsapp_phone || "");
        setCnpj(response.data.cnpj || "");
        setLat(response.data.lat || "");
        setLong(response.data.lng || "");
        setDescription(response.data.description || "");
        setCategory(response.data.category || 0);
        setPath(response.data.path || "https://ischool.illinois.edu/sites/default/files/styles/normal_square/public/images/empty-avatar-01_1.jpg?itok=hAW4etvU")
        // console.log(response.data)
        setPriority(response.data.is_priority)
        setLoading(false)
        // setBlur("")
      })
      .catch(err => {
        console.log('Erro: ' + JSON.stringify(err.response.data))
      })
  }

  async function editPartner(id) {
    // console.log("user id: " + userId())
    const data = {
      name,
      category,
      email,
      whatsapp_phone: whatsapp,
      phone,
      cnpj,
      address,
      state,
      city,
      country,
      cep: cepAddress,
      lat,
      lng: long,
      description
    }

    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.put('/partner/' + id, data, config)
      .then(response => {
        // setType("succes")
        // setMessage("Dados atualizados com sucesso!")
        notify("success", "Usuário atualizado com sucesso!")
        if (file.fileName !== "")
          storePicture(userId())

        else
          window.location.reload();
      })
      .catch(error => {
        console.log('Erro: ' + JSON.stringify(error.response.data))
        // setErro(true)
        // setType("warning")
        // setMessage("Erro! Não foi possível atualizar os dados com sucesso!")
        notify("warning", "Erro! Não foi possível atualizar os dados! Tente mais tarde.");
      })
    notify("tc");

  }

  async function storePicture(id) {

    let data = new FormData();
    data.append('profile_pic', file, file.fileName);
    const config = {
      headers: { Authorization: `Bearer ${token()}` }
    }

    //ENVIAR NOTIFICAÇÕES DE ERRO OU SUCESSO AQUI
    await api.post('/sessions/' + id + "/image", data, config)
      .then(response => {
        console.log("Foto atualizada com sucesso")
        window.location.reload();
      })
      .catch(error => {
        console.log('Erro: ' + JSON.stringify(error.response.data))
        notify("warning", "Erro ao atualizar foto! Tente novamente mais tarde.");
        window.location.reload();
      })

  }

  function error() {
    if (name.length !== 0 && email.length !== 0 && lat.length !== 0 && long.length !== 0 && cnpj.length !== 0) {
      if (lat.length > 24 || long.length > 24) {
        if (lat.length > 24) {
          setErrorLat("has-danger")
          notify("warning", "Erro! O campo de latitude foi preenchido de forma inválida!")
        }
        if (long.length > 24) {
          setErrorLng("has-danger");
          notify("warning", "Erro! O campo de longitude foi preenchido de forma inválida!")
        }
        return true;
      }
      return false;
    }
    else {
      if (name.length === 0) {
        setErrorName("has-danger")
      }
      if (email.length === 0) {
        setErrorEmail("has-danger");
      }
      if (lat.length === 0) {
        setErrorLat("has-danger");
      }
      if (long.length === 0) {
        setErrorLng("has-danger");
      }
      if (cnpj.length === 0) {
        setErrorCnpj("has-danger")
      }
      notify("warning", "Nem todos os campos foram preenchidos!" +
        " Por favor preencha todos os campos obrigatórios para poder atualizar seu cadastro!")
    }
    return true;
  }

  function onSubmit() {

    if (!error()) {
      setLoading(true)
      editPartner(partnerId());
      setLoading(false)
    }
 
  }

  function notify(situation, text) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),

      type: situation,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlert.current.notificationAlert(options);
  };


  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edite seu perfil</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup className={errorName}>
                        <label>Nome*</label>
                        <Input
                          placeholder="Nome"
                          type="text"
                          value={name}
                          onChange={handleNameChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup className={errorEmail}>
                        <label htmlFor="exampleInputEmail1">
                          E-mail*
                          </label>
                        <Input placeholder="exemplo@email.com"
                          type="email"
                          disabled={true}
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <label>Foto</label>
                        <FileInput path={setPath} file={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup className={errorCnpj}>
                        <label>CNPJ*</label>
                        <Input placeholder="CNPJ"
                          type="text"
                          value={cnpj}
                          onChange={handleCnpjChange} />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup className={errorCep}>
                        <label>CEP</label>
                        <Input placeholder="CEP"
                          type="text"
                          value={cepAddress}
                          onChange={(txt) => setCep(mask(txt.target.value, '99999-999'))}
                          onBlur={handleCepChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                  loading ?
                    <Row className="justify-content-md-center">
                      <Col md="auto">
                        <CircularProgress />
                      </Col>
                    </Row>
                  :                
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Logradouro</label>
                        <Input
                          placeholder="Avenida, Bairo, Rua, Número..."
                          type="text"
                          value={address}
                          onChange={(txt) => setAddress(txt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
              }
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>País</label>
                        <Input
                          placeholder="País"
                          type="text"
                          value={country}
                          onChange={(txt) => setCountry(txt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Estado</label>
                        <Input
                          placeholder="Estado"
                          type="text"
                          value={state}
                          onChange={(txt) => setState(txt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Cidade</label>
                        <Input
                          placeholder="Cidade"
                          type="text"
                          value={city}
                          onChange={(txt) => setCity(txt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup className={errorLat}>
                        <label>Latitude*</label>
                        <Input
                          placeholder="-99.999999"
                          type="text"
                          value={lat}
                          onChange={handleLatChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup className={errorLng}>
                        <label>Longitude*</label>
                        <Input
                          placeholder="99.999999"
                          type="text"
                          value={long}
                          onChange={handleLongChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Tipo de produto</label>
                        <Input type="select"
                          name="select"
                          id="exampleSelect1"
                          value={category}
                          onChange={handleCategoryChange}
                        >
                          <option value={0} >Produto comercial</option>
                          <option value={1} >Amostra grátis</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>WhatsApp</label>
                        <Input
                          placeholder="Número do WhatsApp"
                          type="text"
                          value={whatsapp}
                          onChange={(txt) => setWhatsapp(mask(txt.target.value, '+99(99)99999-9999'))}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          placeholder="Número de Telefone"
                          type="text"
                          value={phone}
                          onChange={(txt) => setPhone(mask(txt.target.value, '+99(99)99999-9999'))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Descrição</label>
                        <Input
                          cols="80"
                          placeholder="Descreva aqui os serviços prestados pela empresa e outras informações importantes..."
                          rows="4"
                          type="textarea"
                          value={description}
                          onChange={(txt) => setDescription(txt.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <label>Tem prioridade</label>
                      <div className="ml-3">
                        <Row>
                          <FormGroup
                            check
                            inline
                            className="form-check-radio"
                            disabled={true}
                          >
                            <Label className="form-check-label">
                              <Input
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios11"
                                value="Sim"
                                checked={priority}
                                disabled={true}
                              />
                                                            Sim
                                                            <span className="form-check-sign"></span>
                            </Label>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup
                            check
                            inline
                            className="form-check-radio"
                            disabled={true}
                          >
                            <Label className="form-check-label">
                              <Input
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios12"
                                value="Não"
                                checked={!priority}
                                disabled={true}
                              />
                                                            Não
                                                            <span className="form-check-sign"></span>
                            </Label>
                          </FormGroup>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="info" type="submit" onClick={onSubmit}>
                  Salvar
                  </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <img
                  alt="..."
                  className="img-partner"
                  src={path}
                />
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <p className="description">{name}</p>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default UserProfile;
