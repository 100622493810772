import React, { useState,useEffect } from 'react'
import { Line } from "react-chartjs-2";
import api from '../../../services/api'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";

import {
    Title
} from '../styles'

import icone from '../Dashboard_Icons/AcessosDiarios.svg'
var dataAtual = new Date();
var mes = dataAtual.getMonth();
var ano = dataAtual.getFullYear();
if(mes === 0){
    mes = 12;
    ano -=1;
}
let dias =  new Date(ano,mes,0).getDate();
function mesAnterior(dias){
   
    let listadias = [];
    for(var i = 1; i <= dias;i++){
        listadias.push(i);
    }
    return listadias;
}


export default function CardChart(props) {
   const[visitas,setVisitas ]= useState([]);
    async function getVisitas(){
        
        await api.get(`/getvisitadiaria/${ano}-${mes}-${dias}`)
            .then(response =>{
                let diasmesanterior = [
                    response.data[0][0].dia1,
                    response.data[0][0].dia2,
                    response.data[0][0].dia3,
                    response.data[0][0].dia4,
                    response.data[0][0].dia5,
                    response.data[0][0].dia6,
                    response.data[0][0].dia7,
                    response.data[0][0].dia8,
                    response.data[0][0].dia9,
                    response.data[0][0].dia10,
                    response.data[0][0].dia11,
                    response.data[0][0].dia12,
                    response.data[0][0].dia13,
                    response.data[0][0].dia14,
                    response.data[0][0].dia15,
                    response.data[0][0].dia16,
                    response.data[0][0].dia17,
                    response.data[0][0].dia18,
                    response.data[0][0].dia19,
                    response.data[0][0].dia20,
                    response.data[0][0].dia21,
                    response.data[0][0].dia22,
                    response.data[0][0].dia23,
                    response.data[0][0].dia24,
                    response.data[0][0].dia25,
                    response.data[0][0].dia26,
                    response.data[0][0].dia27,
                    response.data[0][0].dia28,
                ];
                if(response.data[0][0].dia29 !== undefined){
                    diasmesanterior.push(response.data[0][0].dia29);
                }
                
                if(response.data[0][0].dia30 !== undefined){
                    diasmesanterior.push(response.data[0][0].dia30);
                }
                
                if(response.data[0][0].dia31 !== undefined){
                    diasmesanterior.push(response.data[0][0].dia31);
                }
                // console.log(diasmesanterior)
               setVisitas(diasmesanterior);
            })
            .catch(error =>{
                console.log('error')
            })
    }
    useEffect(() => {
        
        getVisitas();
    }, [])
    let chartExample1 = {
        data1: canvas => {
            let ctx = canvas.getContext("2d");
    
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
    
            return {
                labels: mesAnterior(dias),
                datasets: [
                    {
                        label: "Acessaram o aplicativo",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: visitas
                    }
                ]
            };
        }
    }
    return (
        <Card className="card-chart"
            style={{
                borderRadius: '20px',
                boxShadow: '0 4px 4px rgba(0,0,0,0.25)'
            }}>
            <CardHeader>
                <Row>
                    <Col sm={10}>
                        <Title>Acessos Diários</Title>
                    </Col>
                    <Col>
                        <div className="float-right">
                            <img src={icone} alt="icone" style={{ maxHeight: '40px' }} />
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <div>
                <CardBody>
                    <Row>
                        <Col>
                            <Line
                                data={chartExample1['data1']}
                                options={chartExample1.options}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </div>
        </Card>
    )
}