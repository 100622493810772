import React,{useState, useEffect} from 'react'
import api from '../../../services/api'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";

import {
    Title,
    CadastrosNum
} from '../styles'

import icone from '../Dashboard_Icons/UsuariosCadastrados.svg'


export default function CardAllUsers(props) {
    const [qtdusuario, setQtdUsuario] = useState("");
    useEffect(() => {
        usuarioscadastrados()
      }, [])
    async function usuarioscadastrados() {
        await api.get('/GetAll')
            .then(response => {
               console.log(response.data);
                // console.log('tudo certo' )
                setQtdUsuario(response.data);
            })
            .catch(error => {
                console.log('error')
            })
    }
    return (<Card className="card-chart"
        style={{
            borderRadius: '20px',
            boxShadow: '0 4px 4px rgba(0,0,0,0.25)'
        }}>
        <CardHeader>
            <Row>
                <Col>
                    <Title>Usuários Cadastrados</Title>
                </Col>
                <Col sm={2}>
                    <div className="float-right">
                        <img src={icone} alt="icone" style={{ maxHeight: '40px' }} />
                    </div>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <Col ms={12}>
                <CadastrosNum>
                    {qtdusuario}
                </CadastrosNum>
            </Col>
        </CardBody>
    </Card>)
}