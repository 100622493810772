export const login = (token, user_type, id) => {
    localStorage.setItem('TOKEN_KEY', token);
    localStorage.setItem('USER_TYPE', user_type);
    localStorage.setItem('USER_ID', id);
}

export const logout = () => {
    localStorage.removeItem('TOKEN_KEY');
    localStorage.removeItem('USER_TYPE');
    localStorage.removeItem('USER_ID');
    localStorage.removeItem('PARTNER_ID');
    localStorage.removeItem('DOCTOR_ID');
}

export const isLogin = () => {
    if (localStorage.getItem('TOKEN_KEY')) {
        return true;
    }
    return false;
}

export const token = () => {
    return localStorage.getItem('TOKEN_KEY')
}

export const userType = () => {
    return localStorage.getItem('USER_TYPE')
}

export const userId = () => {
    return localStorage.getItem('USER_ID')
}

export const partnerId = (id) => {
    if (id)
        localStorage.setItem('PARTNER_ID', id);
    return localStorage.getItem('PARTNER_ID')
}

export const doctorId = (id) => {
    if (id)
        localStorage.setItem('DOCTOR_ID', id);
    return localStorage.getItem('DOCTOR_ID')
}