import React from 'react'

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";

import {
    Title,
} from '../styles'

import MaterialTable from 'material-table';

export default function CardTable(props) {

    return (

        <>
            <Card className="card-chart"
                style={{
                    borderRadius: '20px',
                    boxShadow: '0 4px 4px rgba(0,0,0,0.25)'
                }}>
                <CardHeader>
                    <Row>
                        <Col>
                            <Title>{props.title}</Title>

                        </Col>
                        <Col sm={2}>
                            <div className="float-right">
                                <img src={props.icon} alt="icone" style={{maxHeight: '40px'}} />
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <MaterialTable
                        columns={props.colunas}

                        data={props.dados}

                        options={{
                            showTitle: false,
                            header: true,
                            search: false,
                            paging: false,
                            emptyRowsWhenPaging: false,
                            filtering: false,
                            grouping: false,
                            toolbar: false,

                            headerStyle: {
                                color: 'rgba(0,0,0,0.5)',
                                fontWeight: '500',
                                fontFamily: "'Poppins' sans-serif",
                            }
                        }}

                        style={{
                            boxShadow: '0 0 0 black'
                        }}

                    />
                </CardBody>
            </Card>
        </>
    )
}